import { MediaType, SharedDataType } from 'APP/constants/sharedData';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';

export default async ({ groupId }) => {
  try {
    const group = Entities.GroupsStore.getGroupById(groupId);
    if (!group) {
      return;
    }

    const mediaTypes = [
      MediaType.Image,
      MediaType.Video,
      MediaType.File,
      MediaType.Link,
      MediaType.Audio,
      MediaType.Voice,
      MediaType.Article,
    ];

    const { payloadTypeToCount } = await api.media.getGroupMessagesByMediaTypesCount({
      groupId,
      mediaTypes,
    });

    const count = {
      [SharedDataType.Media]:
        payloadTypeToCount[MediaType.Image] + payloadTypeToCount[MediaType.Video],
      [SharedDataType.Files]: payloadTypeToCount[MediaType.File],
      [SharedDataType.Links]: payloadTypeToCount[MediaType.Link],
      [SharedDataType.Audio]: payloadTypeToCount[MediaType.Audio],
      [SharedDataType.Voices]: payloadTypeToCount[MediaType.Voice],
      [SharedDataType.Articles]: payloadTypeToCount[MediaType.Article],
    };
    const sharedDataTypes = Object.keys(count);

    for (let sharedDataType of sharedDataTypes) {
      if (group.sharedData[sharedDataType]) {
        group.sharedData[sharedDataType].updateCount(count[sharedDataType]);
      }
    }

    return null;
  } catch (e) {
    logger.get('Tasks').error('media.updateSharedDataCount', e);

    return null;
  }
};
