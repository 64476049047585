import { useState } from 'react';

import { showAlert } from 'APP/Tasks/app/showAlert/showAlert';
import * as features from 'APP/packages/features/features';
import { useTranslation } from 'APP/packages/translations';

export interface IShareScreenButtonPresenterParams {
  isActive: boolean;
  shareScreen(): Promise<void>;
  stopSharingScreen(): void;
}

interface IShareScreenButtonPresenter {
  isProcessing: boolean;
  onClick(): Promise<void>;
}

export function useShareScreenButtonPresenter({
  isActive,
  shareScreen,
  stopSharingScreen,
}: IShareScreenButtonPresenterParams): IShareScreenButtonPresenter {
  const { t } = useTranslation();

  const [isProcessing, setIsProcessing] = useState(false);

  const onClick = async (): Promise<void> => {
    if (isActive) {
      stopSharingScreen();
      return;
    }

    if (!features.isSupportScreenSharing()) {
      showAlert(t('calls_safari_not_supported_alert'));
      return;
    }

    setIsProcessing(true);
    await shareScreen();
    setIsProcessing(false);
  };

  return { isProcessing, onClick };
}
