import { useCallback, useMemo } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'APP/store';

interface IAudioPlayerPresenter {
  isPlaying: boolean;
  isActiveTrack: boolean;
  progress: number;
  formattedDuration: number;
  onTogglePlay(): void;
}

interface IAudioPlayerPresenterOptions {
  groupId: string;
  messageId: string;
  payloadId: string;
  clientUuid: string;
  duration: string;
}

export function useAudioPlayerPresenter({
  groupId,
  messageId,
  payloadId,
  clientUuid,
  duration,
}: IAudioPlayerPresenterOptions): IAudioPlayerPresenter {
  const audioPlayer = Entities.miniAudioPlayer;

  const onTogglePlay = useCallback(() => {
    if (clientUuid === audioPlayer.payloadId || payloadId === audioPlayer.payloadId) {
      audioPlayer.setIsPlaying(!audioPlayer.isPlaying);
    } else {
      Tasks.miniAudioPlayer.openAudioPlayer(groupId, messageId, clientUuid || payloadId);
    }
  }, [groupId, messageId, payloadId, clientUuid]);

  const isPlaying = useMemo(() => {
    return (
      audioPlayer.isPlaying &&
      (audioPlayer.payloadId === payloadId || audioPlayer.payloadId === clientUuid)
    );
  }, [audioPlayer.isPlaying, audioPlayer.payloadId, payloadId, clientUuid]);

  const isActiveTrack = useMemo(() => {
    return audioPlayer.payloadId === payloadId || audioPlayer.payloadId === clientUuid;
  }, [audioPlayer.payloadId, payloadId, clientUuid]);

  const formattedDuration = useMemo(() => {
    return parseInt(duration, 10) / 1000;
  }, [duration]);

  return {
    isPlaying,
    isActiveTrack,
    progress: audioPlayer.progress,
    formattedDuration,
    onTogglePlay,
  };
}
