import React, { FC, MouseEventHandler, ReactElement } from 'react';

import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { Layout } from '../components/Layout';

interface IStickerProps {
  message: ChatMessage;
  title: string | ReactElement;
  onClick: MouseEventHandler;
}

export const MiniatureMessageSticker: FC<IStickerProps> = (props) => {
  const { message, title, onClick } = props;

  return (
    <Layout
      imageUrl={message.payload.url}
      graphicSize={message.payload.graphicSize}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};
