import Entities from 'STORE';
import { VideoPlayerType } from 'UIKIT/Media/VideoPlayer/VideoPlayer.constants';

interface IInitVideoPlayer {
  container: HTMLDivElement;
  url: string;
  previewUrl: string | null;
  type?: VideoPlayerType;
  onExpandMini: (() => void) | null;
  onShowMini: (() => void) | null;
}

export function initVideoPlayer({
  container,
  url,
  type = VideoPlayerType.Default,
  previewUrl = null,
  onExpandMini = null,
  onShowMini = null,
}: IInitVideoPlayer): void {
  const globalVideoPlayer = Entities.GlobalVideoPlayer;

  globalVideoPlayer.setContainer(container);
  globalVideoPlayer.setType(type);
  globalVideoPlayer.setUrl(url);
  globalVideoPlayer.setPreviewUrl(previewUrl);
  globalVideoPlayer.setExpandMiniHandler(onExpandMini);
  globalVideoPlayer.setShowMiniHandler(onShowMini);
}
