import classNames from 'classnames';
import React, { FC } from 'react';

import customScrollStyles from '../../../CustomScroll/CustomScroll.styles.m.css';
import { PlayIcon, PlayIconState, PlayIconType } from '../../../PlayIcon/PlayIcon';
import { IAudioPlayerTrack } from '../AudioPlayer.types';
import { AudioPlayerTrackCaption } from '../AudioPlayerTrackCaption/AudioPlayerTrackCaption';
import { useAudioPlayerListPresenter } from './AudioPlayerPlayList.presenter';
import styles from './AudioPlayerPlayList.styles.m.css';

interface IAudioPlayerPlayListProps {
  isPlaying: boolean;
  activeTrack: IAudioPlayerTrack | null;
  items: IAudioPlayerTrack[];
  progress: number;
  isOpened: boolean;
  onTogglePlay(): void;
  onChangeTrack(track: IAudioPlayerTrack): void;
}

export const AudioPlayerPlayList: FC<IAudioPlayerPlayListProps> = ({
  isPlaying,
  activeTrack,
  items,
  progress,
  isOpened,
  onTogglePlay,
  onChangeTrack,
}) => {
  const { listRef, maxHeight, changeTrack } = useAudioPlayerListPresenter({
    activeTrack,
    items,
    isOpened,
    onTogglePlay,
    onChangeTrack,
  });

  return (
    <div
      className={classNames(styles.container, { [styles.opened]: isOpened })}
      style={{ maxHeight }}
    >
      <div
        className={classNames(styles.list, customScrollStyles.container)}
        ref={listRef}
      >
        {items.map((item) => (
          <div
            className={styles.item}
            key={item.id}
            data-is-active={item.id === activeTrack?.id}
          >
            <PlayIcon
              className={styles.icon}
              type={
                item.id === activeTrack?.id && isPlaying ? PlayIconType.Pause : PlayIconType.Play
              }
              state={PlayIconState.Regular}
              onClick={() => changeTrack(item)}
            />

            <div className={styles.info}>
              <button
                className={styles.title}
                onClick={() => changeTrack(item)}
              >
                {item.title}
              </button>

              <AudioPlayerTrackCaption
                isPlaying={item.id === activeTrack?.id}
                duration={item.duration}
                progress={progress}
                size={item.size}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
