import { reaction } from 'mobx';
import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import Entities from 'STORE';
import { VideoPlayerType } from 'UIKIT/Media/VideoPlayer/VideoPlayer.constants';
import { IVideoPlayerConfig } from 'UIKIT/Media/VideoPlayer/VideoPlayer.types';

interface IGlobalVideoPlayerPresenter {
  container: HTMLDivElement;
  position: { x: number; y: number } | undefined;
  isPlaying: boolean;
  isFullScreenMode: boolean;
  removeAudioSource: (() => void) | null;
  url: string | null;
  previewUrl: string | null;
  type: VideoPlayerType | null;
  canShowMiniPlayer: boolean;
  canExpandMiniPlayer: boolean;
  isHidden: boolean;
  isDraggable: boolean;
  isMini: boolean;
  playerConfig: Partial<IVideoPlayerConfig>;
  onShowMiniPlayer(): void;
  onExpandMiniPlayer(): void;
  onCloseMiniPlayer(): void;
  setPlayerConfig(config: Partial<IVideoPlayerConfig>): void;
  onDragEnd(_: any, data: any): void;
  pauseVideo(): void;
  onTogglePlay(isPlaying: boolean): void;
  onToggleFullScreenMode(isFullScreenMode: boolean): void;
}

export function useGlobalVideoPlayerPresenter(): IGlobalVideoPlayerPresenter {
  const presenter = useLocalStore<IGlobalVideoPlayerPresenter>(() => ({
    container: document.createElement('div'),
    position: undefined,
    isPlaying: true,
    isFullScreenMode: false,
    removeAudioSource: null,

    get url(): string | null {
      return Entities.GlobalVideoPlayer.url;
    },

    get previewUrl(): string | null {
      return Entities.GlobalVideoPlayer.previewUrl;
    },

    get type(): VideoPlayerType | null {
      return Entities.GlobalVideoPlayer.type;
    },

    onShowMiniPlayer(): void {
      const globalVideoPlayer = Entities.GlobalVideoPlayer;

      globalVideoPlayer.setContainer(null);
      globalVideoPlayer.setType(VideoPlayerType.Mini);
      if (globalVideoPlayer.onShowMiniHandler) {
        globalVideoPlayer.onShowMiniHandler();
      }

      presenter.position = undefined;
    },

    get canShowMiniPlayer(): boolean {
      return !!Entities.GlobalVideoPlayer.onShowMiniHandler;
    },

    onExpandMiniPlayer(): void {
      const globalVideoPlayer = Entities.GlobalVideoPlayer;

      if (globalVideoPlayer.onExpandMiniHandler) {
        globalVideoPlayer.onExpandMiniHandler();
      }
    },

    get canExpandMiniPlayer(): boolean {
      return !!Entities.GlobalVideoPlayer.onExpandMiniHandler;
    },

    onCloseMiniPlayer(): void {
      Tasks.globalVideoPlayer.closeVideoPlayer();
    },

    get isHidden(): boolean {
      return !Entities.GlobalVideoPlayer.url;
    },

    get isDraggable(): boolean {
      return presenter.isMini;
    },

    get isMini(): boolean {
      return Entities.GlobalVideoPlayer.type === VideoPlayerType.Mini;
    },

    get playerConfig(): Partial<IVideoPlayerConfig> {
      return Entities.GlobalVideoPlayer.config;
    },

    setPlayerConfig(config: Partial<IVideoPlayerConfig>): void {
      return Tasks.globalVideoPlayer.setPlayerConfig(config);
    },

    onDragEnd(_: any, data: any): void {
      presenter.position = {
        x: data.x,
        y: data.y,
      };
    },

    pauseVideo(): void {
      presenter.isPlaying = false;
      presenter.isFullScreenMode = false;
    },

    onTogglePlay(isPlaying: boolean): void {
      presenter.isPlaying = isPlaying;

      if (isPlaying && !presenter.removeAudioSource) {
        presenter.removeAudioSource = Tasks.app.audioSource.setCurrentSource(
          AudioSourceType.Video,
          presenter.pauseVideo
        );
      }

      if (!isPlaying && presenter.removeAudioSource) {
        presenter.removeAudioSource();
        presenter.removeAudioSource = null;
      }
    },

    onToggleFullScreenMode(isFullScreenMode: boolean): void {
      presenter.isFullScreenMode = isFullScreenMode;
    },
  }));

  useEffect(() => {
    document.body.appendChild(presenter.container);

    const dispose = reaction(
      () => Entities.GlobalVideoPlayer.container,
      (target) => {
        if (target) {
          target.appendChild(presenter.container);
        } else {
          document.body.appendChild(presenter.container);
        }
      }
    );

    return () => {
      document.body.removeChild(presenter.container);
      dispose();
    };
  }, []);

  useEffect(() => {
    Tasks.globalVideoPlayer.initPlayerConfig();
  }, []);

  return presenter;
}
