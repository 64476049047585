import { IGraphicSize } from 'APP/model/common/commonModel.types';
import { PayloadType } from 'APP/model/message/messageModel.types';
import Image from 'STORE/Messages/Message/Payload/Image';
import Video from 'STORE/Messages/Message/Payload/Video';

interface IMediaPreviewLocalStore {
  isImageLocal: boolean;
  imageUrl: string;
  imageGraphicSize: IGraphicSize;
}

export const useMediaPreviewPresenter = (payload: Image | Video) => {
  const presenter: IMediaPreviewLocalStore = {
    get isImageLocal(): boolean {
      return !!payload.localPreview;
    },

    get imageUrl(): string {
      if (presenter.isImageLocal) {
        return payload.localPreview;
      }

      return payload.payloadType === PayloadType.Video ? payload.previewUrl : payload.url;
    },

    get imageGraphicSize(): IGraphicSize {
      return {
        width: payload.graphicSize?.width || 0,
        height: payload.graphicSize?.height || 0,
      };
    },
  };

  return presenter;
};
