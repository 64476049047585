export enum MediaType {
  Audio = 'AudioMessage',
  Album = 'Album',
  Article = 'Article',
  Link = 'Link',
  File = 'File',
  Image = 'Image',
  Video = 'Video',
  Voice = 'VoiceMessage',
}

export enum SharedDataType {
  Media = 'media',
  Files = 'files',
  Links = 'links',
  Audio = 'audio',
  Voices = 'voices',
  Articles = 'articles',
}

export const SHARED_DATA_MEDIA_TYPES_MAP = {
  [SharedDataType.Media]: [MediaType.Image, MediaType.Video],
  [SharedDataType.Files]: [MediaType.File],
  [SharedDataType.Links]: [MediaType.Link],
  [SharedDataType.Audio]: [MediaType.Audio],
  [SharedDataType.Voices]: [MediaType.Voice],
  [SharedDataType.Articles]: [MediaType.Article],
};

export const MEDIA_PREVIEW_THUMBNAIL_SIZE = 40;
