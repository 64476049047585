import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AudioPlayer } from 'APP/components/AudioPlayer/AudioPlayer';
import { AudioComponent } from 'APP/constants/audio';
import { IconSize } from 'MAIN/ChatView/components/UploaderState/UploaderState.types';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

interface IAudioPayloadProps {
  message: ChatMessage;
  componentName: AudioComponent;
  className?: string;
  size?: IconSize;
  errorClassName?: string;
}

export const AudioPayload: FC<IAudioPayloadProps> = observer((props) => {
  const { message, componentName, className } = props;
  const {
    id: payloadId,
    fileName,
    fileSize,
    duration,
    uploadProgress,
    cancelUploadAndDeleteMessage,
  } = message.payload;

  return (
    <AudioPlayer
      className={className}
      size={fileSize}
      fileName={fileName}
      duration={duration}
      componentName={componentName}
      messageId={message.id}
      clientUuid={message.clientUuid}
      groupId={message.groupId}
      payloadId={payloadId || message.id}
      uploadProgress={uploadProgress}
      cancelUploadAndDeleteMessage={cancelUploadAndDeleteMessage}
      hasError={message.hasError}
    />
  );
});
