import classNames from 'classnames';
import React from 'react';

import { ForwardMessage } from '../../components/ForwardMessage/ForwardMessage';
import Quote from '../../components/Quote';
import { SenderName } from '../../components/SenderName/SenderName';
import styles from './styles.m.scss';

const Header = ({ message, isShowForward }) => {
  const senderNameClasses = classNames(styles.senderName, {
    [styles.wideGap]: message.quotedMessage || isShowForward,
    [styles.dynamicLength]: !message.quotedMessage && !isShowForward,
  });

  return (
    <div className={styles.header}>
      <SenderName
        message={message}
        className={senderNameClasses}
      />
      {message.quotedMessage && <Quote message={message.quotedMessage} />}
      {isShowForward && (
        <ForwardMessage
          className={styles.forward}
          message={message}
        />
      )}
    </div>
  );
};

export default Header;
