import React from 'react';

import IcVoice16 from 'ICONS/ic-s-voice-message-16.svg';

import { Layout } from '../components/Layout';

const Voice = ({ message, title, onClick }) => {
  return (
    <Layout
      title={title}
      Icon={IcVoice16}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Voice;
