import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { Popup } from 'APP/components/Popup/Popup';
import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import { useTranslation } from 'APP/packages/translations';
import { Button } from 'UIKIT/Button/Button';
import customScrollStyles from 'UIKIT/CustomScroll/CustomScroll.styles.m.css';
import { MenuItem } from 'UIKIT/MenuItem/MenuItem';
import { SwitchMenuItem } from 'UIKIT/SwitchMenuItem/SwitchMenuItem';

import { ICommonPopupsProps } from '../../PopupManager.types';
import { useClosePopupOnNavigation } from '../../hooks/useClosePopupOnNavigation';
import { useGroupPermissionsPopupPresenter } from './GroupPermissionsPopup.presenter';
import styles from './GroupPermissionsPopup.styles.m.css';
import { IGroupPermissionsPopupParams } from './GroupPermissionsPopup.types';
import { SupportedMediaTypes } from './SupportedMediaTypes/SupportedMediaTypes';

type TGroupPermissionsPopupProps = ICommonPopupsProps<IGroupPermissionsPopupParams>;

export const GroupPermissionsPopup: FC<TGroupPermissionsPopupProps> = observer((props) => {
  const { t } = useTranslation();
  const presenter = useGroupPermissionsPopupPresenter(props);
  useClosePopupOnNavigation(props.popupInstance);

  return (
    <Popup
      title={t('permissions_label')}
      isOpened
      isHidden={props.popupInstance.isHidden}
      onClose={presenter.onClose}
    >
      <AddKeyPressHandler onEsc={presenter.onClose} />
      <div className={classNames(styles.content, customScrollStyles.container)}>
        {presenter.isShowSystemMessageSection && (
          <>
            <SwitchMenuItem
              className={styles.switch}
              title={t('permissions_switch_system_message')}
              isChecked={presenter.systemMessagesEnabled}
              onChange={presenter.onToggleSystemMessage}
            />

            <div className={styles.divider} />
          </>
        )}

        <h6 className={styles.caption}>{t('group_chat_permissions_description')}</h6>
        <SupportedMediaTypes
          mediaTypes={presenter.permissions.supportedMediaTypes}
          onChange={presenter.onChangeSupportedMediaTypes}
        />
        {presenter.permissionItems.map(({ id, title, isChecked, onChange }) => (
          <SwitchMenuItem
            key={id}
            className={styles.switch}
            title={title}
            isChecked={isChecked}
            onChange={onChange}
          />
        ))}

        {presenter.isShowCommentModerationSection && (
          <>
            <div className={styles.divider} />
            <h6 className={styles.caption}>{t('comment_moderation_section')}</h6>
            <SwitchMenuItem
              className={styles.switchWithDescription}
              title={t('hide_offencive_words')}
              description={t('comment_moderation_hide_description')}
              isChecked={presenter.obsceneFilterEnabled}
              isDisabled={!presenter.canChangeObsceneFilter}
              onChange={presenter.onToggleObsceneFilter}
              onClick={presenter.onClickObsceneFilter}
            />
            <MenuItem
              className={styles.switch}
              title={t('manage_offencive_words')}
              count={presenter.keywordsCount > 0 ? presenter.keywordsCount : undefined}
              onClick={presenter.onOpenKeywordFiltersPopup}
            />
          </>
        )}
      </div>

      <div className={styles.footer}>
        <Button
          onClick={presenter.onSave}
          className={styles.button}
          isDisabled={!presenter.isEdited}
          theme="1"
        >
          {t('save')}
        </Button>
      </div>
    </Popup>
  );
});
