import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { spaceModel } from 'APP/model/model';
import { useTranslation } from 'APP/packages/translations';
import { KeywordFiltersPopup } from 'MAIN/PopupManager/Popups/KeywordFiltersPopup/KeywordFiltersPopup';
import Entities from 'STORE';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Space } from 'STORE/Spaces/Space';

import { ICommonPopupsProps } from '../../PopupManager.types';

export interface ISpacePermissionsPopupParams {
  spaceId: string;
}

interface ISpacePermissionsPopupPresenter {
  obsceneFilterEnabled: boolean;
  childPopup: Popup | null;
  space: Space | null;
  hasBackButton: boolean;
  isShowCommentModerationSection: boolean;
  isObsceneFilterEdited: boolean;
  isEdited: boolean;
  keywordsCount: number;
  init(): void;
  onClose(): void;
  onBack(): void;
  onToggleObsceneFilter(): void;
  onChangeKeywordFilters(list: string[]): Promise<void>;
  onOpenKeywordFiltersPopup(): void;
  onSave(): void;
}

const getSpaceObsceneFilterEnabled = (space: Space | null): boolean => {
  return space?.obsceneFilterEnabled || false;
};

export function useSpacePermissionsPopupPresenter({
  popupInstance,
  onClose,
  onBack,
  params,
}: ICommonPopupsProps<ISpacePermissionsPopupParams>): ISpacePermissionsPopupPresenter {
  const { spaceId } = params!;
  const { t } = useTranslation();

  const presenter = useLocalStore<ISpacePermissionsPopupPresenter>(() => ({
    obsceneFilterEnabled: false,
    childPopup: null,

    get space(): Space | null {
      return Entities.spacesStore.getById(spaceId);
    },

    get hasBackButton(): boolean {
      return !!onBack;
    },

    get isShowCommentModerationSection(): boolean {
      return presenter.space?.isPublic || false;
    },

    get isObsceneFilterEdited(): boolean {
      return presenter.obsceneFilterEnabled !== getSpaceObsceneFilterEnabled(presenter.space);
    },

    get isEdited(): boolean {
      return presenter.isObsceneFilterEdited;
    },

    get keywordsCount(): number {
      return presenter.space?.keywordFilters || 0;
    },

    init(): void {
      presenter.obsceneFilterEnabled = getSpaceObsceneFilterEnabled(presenter.space);
    },

    onClose(): void {
      presenter.childPopup?.close();
      popupInstance.close();
      onClose?.();
    },

    onBack(): void {
      popupInstance.close();
      onBack?.();
    },

    onToggleObsceneFilter(): void {
      presenter.obsceneFilterEnabled = !presenter.obsceneFilterEnabled;
    },

    async onChangeKeywordFilters(list: string[]): Promise<void> {
      try {
        await spaceModel.setKeywordFilters({
          spaceId: presenter.space!.id,
          keywords: list!,
        });
      } catch {
        Tasks.app.showToast(t('common_somethings_wrong_error'));
      }
    },

    onOpenKeywordFiltersPopup(): void {
      popupInstance?.hide();

      presenter.childPopup = Tasks.app.openPopup(KeywordFiltersPopup, {
        onClose: () => presenter.onClose(),
        onBack: () => popupInstance?.show(),
        params: {
          loadBlackList: () => spaceModel.getKeywordFilters(presenter.space!.id),
          onChange: presenter.onChangeKeywordFilters,
        },
      });
    },

    async onSave(): Promise<void> {
      presenter.onClose();

      if (!presenter.space) {
        return;
      }

      try {
        const updateRequests: Promise<unknown>[] = [];

        if (presenter.isObsceneFilterEdited) {
          updateRequests.push(
            spaceModel.setObsceneFilter({
              spaceId: presenter.space.id,
              enabled: presenter.obsceneFilterEnabled,
            })
          );
        }

        await Promise.all(updateRequests);
      } catch {
        Tasks.app.showToast(t('something_went_wrong'));
      }
    },
  }));

  useEffect(() => {
    presenter.init();
  }, []);

  useEffect(() => {
    if (!presenter.space?.isAdmin && !presenter.space?.isOwner) {
      presenter.onClose();
    }
  }, [presenter.space?.role]);

  return presenter;
}
