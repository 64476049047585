import React from 'react';

import IcFile16 from 'ICONS/ic-s-document-16.svg';

import { Layout } from '../components/Layout';

const File = ({ message, title, onClick }) => {
  return (
    <Layout
      Icon={IcFile16}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default File;
