import {
  MouseEvent as ReactMouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import date from 'APP/packages/date';
import noop from 'UTILS/noop';
import throttle from 'UTILS/throttle';

import { IMediaPlayerProgressBuffer } from '../../hooks/useMediaPlayerProgress';

const ACTION_TO_KEY_CODE_MAP = {
  Back: 'ArrowLeft',
  Forward: 'ArrowRight',
};

const PROGRESS_SHIFT_SECONDS = 5;
const MIN_BUFFER_WITH_TO_DISPLAY = 0.1; // in percentage

const getProgressPercentage = (progress: number, progressMax: number): number => {
  return (progress * 100) / progressMax;
};

const getBufferPercentage = (
  progressBuffer: IMediaPlayerProgressBuffer[],
  progressMax: number
): IMediaPlayerProgressBufferDisplay[] => {
  if (!progressMax || !Array.isArray(progressBuffer)) {
    return [];
  }

  return progressBuffer.reduce((result, item) => {
    const width = ((item.end - item.start) * 100) / progressMax;

    if (width >= MIN_BUFFER_WITH_TO_DISPLAY) {
      result.push({ start: (item.start * 100) / progressMax, width });
    }

    return result;
  }, [] as IMediaPlayerProgressBufferDisplay[]);
};

const getCursorPositionInTime = (
  event: MouseEvent | ReactMouseEvent,
  container: HTMLDivElement,
  progressMax: number
): number => {
  const { clientX } = event;
  const {
    x: progressLeft = 0,
    right: progressRight = 0,
    width: progressWidth = 0,
  } = container.getBoundingClientRect() || {};

  if (progressLeft >= clientX) {
    return 0;
  } else if (progressRight <= clientX) {
    return progressMax;
  } else {
    const percentage = ((clientX - progressLeft) * 100) / progressWidth;
    return (percentage / 100) * progressMax;
  }
};

interface IMediaPlayerProgressBufferDisplay {
  start: number;
  width: number;
}

interface IMediaPlayerProgressOptions {
  isPlaying: boolean;
  progress: number;
  progressMax: number;
  isShowBuffer: boolean;
  progressBuffer: IMediaPlayerProgressBuffer[];
  isShowTooltip: boolean;
  onPlay(): void;
  onPause(): void;
  onProgress(progress: number): void;
  onProgressSelection(isSelected: boolean): void;
}

interface IMediaPlayerProgressResult {
  progressRef: MutableRefObject<HTMLDivElement | null>;
  progressPercentage: number;
  bufferPercentage: IMediaPlayerProgressBufferDisplay[];
  isChangingPosition: boolean;
  tooltipTime: string;
  onMouseDown(event: ReactMouseEvent): void;
}

export function useMediaPlayerProgressPresenter({
  isPlaying,
  progress,
  progressMax,
  isShowBuffer,
  progressBuffer,
  isShowTooltip,
  onPlay,
  onPause,
  onProgress,
  onProgressSelection,
}: IMediaPlayerProgressOptions): IMediaPlayerProgressResult {
  const progressRef = useRef<HTMLDivElement>(null);
  const unsubscribeFunctionsRef = useRef(noop);
  const [isChangingPosition, setChangingPosition] = useState(false);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [bufferPercentage, setBufferPercentage] = useState<IMediaPlayerProgressBufferDisplay[]>([]);
  const isPlayingRef = useRef(isPlaying);
  const shouldResumeVideoRef = useRef(isPlaying);
  const progressCurrentRef = useRef(progress);
  const [tooltipTime, setTooltipTime] = useState('');

  const onMouseDown = useCallback(
    (event: ReactMouseEvent) => {
      if (event.button !== 0) {
        return;
      }

      shouldResumeVideoRef.current = isPlayingRef.current;

      onPause();
      onMouseMove(event);
      setChangingPosition(true);
      onProgressSelection(true);

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);

      unsubscribeFunctionsRef.current = () => {
        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);
      };
    },
    [progressMax, onPlay, onPause, onProgress]
  );

  const onMouseMove = useCallback(
    (event) => {
      const newProgress = progressRef.current
        ? getCursorPositionInTime(event, progressRef.current, progressMax)
        : 0;

      onProgress(newProgress);
    },
    [progressMax, onProgress]
  );

  const onMouseUp = useCallback(() => {
    stopListening();

    if (shouldResumeVideoRef.current) {
      onPlay();
    }

    setChangingPosition(false);
    onProgressSelection(false);
  }, [onPlay]);

  const stopListening = useCallback(() => {
    unsubscribeFunctionsRef.current && unsubscribeFunctionsRef.current();
  }, []);

  useEffect(() => {
    setProgressPercentage(getProgressPercentage(progress, progressMax));
  }, [progress, progressMax]);

  useEffect(() => {
    if (isShowBuffer) {
      setBufferPercentage(getBufferPercentage(progressBuffer, progressMax));
    }
  }, [isShowBuffer, progressBuffer, progressMax]);

  useEffect(() => {
    isPlayingRef.current = isPlaying;
  }, [isPlaying]);

  useEffect(() => stopListening, []);

  useEffect(() => {
    progressCurrentRef.current = progress;
  }, [progress]);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent) => {
      if (progressRef.current !== document.activeElement) {
        return;
      }

      switch (event.key) {
        case ACTION_TO_KEY_CODE_MAP.Back: {
          const newProgress = progressCurrentRef.current - PROGRESS_SHIFT_SECONDS;
          onProgress(newProgress > 0 ? newProgress : 0);
          event.stopPropagation();
          break;
        }
        case ACTION_TO_KEY_CODE_MAP.Forward: {
          const newProgress = progressCurrentRef.current + PROGRESS_SHIFT_SECONDS;
          onProgress(newProgress > progressMax ? progressMax : newProgress);
          event.stopPropagation();
          break;
        }
      }
    };

    if (progressMax) {
      document.addEventListener('keydown', onKeyDown);
    }

    return () => {
      if (progressMax) {
        document.removeEventListener('keydown', onKeyDown);
      }
    };
  }, [progressMax]);

  useEffect(() => {
    const updateTime = throttle((event: Event) => {
      const time = progressRef.current
        ? getCursorPositionInTime(event as MouseEvent, progressRef.current, progressMax)
        : 0;

      setTooltipTime(date.secondsToString(time));
    }, 50);

    if (isShowTooltip) {
      progressRef.current?.addEventListener('mousemove', updateTime);
    }

    return () => {
      progressRef.current?.removeEventListener('mousemove', updateTime);
    };
  }, [isShowTooltip, progressMax]);

  return {
    progressRef,
    onMouseDown,
    progressPercentage,
    bufferPercentage,
    isChangingPosition,
    tooltipTime,
  };
}
