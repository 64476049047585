import { action, computed, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';

import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { Forward } from 'STORE/Messages/Message/Forward/Forward';
import { Payload } from 'STORE/Messages/Message/Payload';
import { DeletedByMe } from 'STORE/Messages/Message/Payload//DeletedByMe/DeletedByMePayload';
import Advertisement from 'STORE/Messages/Message/Payload/Advertisement';
import Album from 'STORE/Messages/Message/Payload/Album';
import Article from 'STORE/Messages/Message/Payload/Article';
import Audio from 'STORE/Messages/Message/Payload/Audio';
import { Buttons } from 'STORE/Messages/Message/Payload/Buttons/Buttons';
import { ButtonsSelected } from 'STORE/Messages/Message/Payload/ButtonsSelected/ButtonsSelected';
import Call from 'STORE/Messages/Message/Payload/Call';
import Contact from 'STORE/Messages/Message/Payload/Contact';
import File from 'STORE/Messages/Message/Payload/File';
import Image from 'STORE/Messages/Message/Payload/Image';
import Location from 'STORE/Messages/Message/Payload/Location';
import Sticker from 'STORE/Messages/Message/Payload/Sticker';
import Stream from 'STORE/Messages/Message/Payload/Stream';
import System from 'STORE/Messages/Message/Payload/System';
import Text from 'STORE/Messages/Message/Payload/Text';
import Video from 'STORE/Messages/Message/Payload/Video';
import Voice from 'STORE/Messages/Message/Payload/Voice';
import Base from 'STORE/base';

export const payloadClasses = {
  [PayloadType.RichText]: Text,
  [PayloadType.File]: File,
  [PayloadType.Image]: Image,
  [PayloadType.Album]: Album,
  [PayloadType.Video]: Video,
  [PayloadType.Contact]: Contact,
  [PayloadType.Location]: Location,
  [PayloadType.Sticker]: Sticker,
  [PayloadType.VoiceMessage]: Voice,
  [PayloadType.AudioMessage]: Audio,
  [PayloadType.Article]: Article,
  [PayloadType.Advertisement]: Advertisement,

  // message deleted only for me
  [PayloadType.MessageWasDeletedByMe]: DeletedByMe,
  // bots
  [PayloadType.Buttons]: Buttons,
  [PayloadType.ButtonsSelected]: ButtonsSelected,
  // call system message
  [PayloadType.Call]: Call,
  [PayloadType.SystemCall]: Call,
  [PayloadType.SystemCallStarted]: Call,
  [PayloadType.SystemCallFinished]: Call,
  //stream
  [PayloadType.SystemStream]: Stream,
  // system
  [PayloadType.SystemChatRenamed]: System,
  [PayloadType.SystemChatDescriptionChanged]: System,
  [PayloadType.SystemChatTypeChanged]: System,
  [PayloadType.SystemChatCategoryChanged]: System,
  [PayloadType.SystemChatAvatarChanged]: System,
  [PayloadType.SystemChatOwnerChanged]: System,
  [PayloadType.SystemChatAdminsAdded]: System,
  [PayloadType.SystemChatAdminsRemoved]: System,
  [PayloadType.SystemChatUsersRemoved]: System,
  [PayloadType.SystemChatUsersBanned]: System,
  [PayloadType.SystemChatJoined]: System,
  [PayloadType.SystemChatAdded]: System,
  [PayloadType.SystemChatLeft]: System,
  [PayloadType.SystemChatMessagePinned]: System,
  [PayloadType.SystemChatNewlyCreatedWelcome]: System,
  [PayloadType.SystemThreadNewlyCreatedWelcome]: System,
  [PayloadType.SystemChatNewlyCreatedPhoneBookUserJoined]: System,
  [PayloadType.SystemChatMessagesSettingsChanged]: System,
  [PayloadType.SystemChatAddedToSpace]: System,
  [PayloadType.SystemChatRemovedFromSpace]: System,
};

export class Message extends Base {
  // TODO: split chat message | system message | virtual(aka new message) | last message into sepparate classes
  payload;
  id;
  groupId;
  senderId;
  clientTime;
  serverTime;
  editTime;
  quotedMessage;
  forwardMessage;
  deepLink;
  type;
  clientUuid;
  usersReactions;

  @observable views;
  @observable expectedServerTime;
  @observable optimisticReactions = null;
  @observable isLoadingTranslate = false;
  @observable isShowTranslate = false;
  @observable threadId = null;

  constructor(data) {
    super(data);

    const {
      id,
      clientUuid,
      groupId,
      senderId,
      clientTime,
      serverTime,
      expectedServerTime,
      editTime,
      quotedMessage,
      deepLink,
      forwardedMessage,
      payload,
      usersReactions,
      views,
      threadId,
      links,
    } = data || {};

    const now = Date.now();
    const payloadType = (payload && payload.payloadType) || null;
    const PayloadClass = payloadClasses[payloadType];

    this.clientUuid = clientUuid || uuidv4();
    this.id = id.toString() || this.clientUuid;
    this.groupId = groupId.toString() || null;
    this.senderId = senderId.toString() || null;
    this.clientTime = parseInt(clientTime, 10) || now; // clientTime
    this.serverTime = parseInt(serverTime, 10) || expectedServerTime; // serverTime
    this.expectedServerTime = this.serverTime;
    this.editTime = parseInt(editTime, 10) || null; // editTime
    this.deepLink = deepLink || null;
    this.threadId = threadId || null;
    this.links = links || [];

    this.quotedMessage = quotedMessage
      ? new Message({ ...quotedMessage, groupId: this.groupId })
      : null;
    this.forward = forwardedMessage ? new Forward({ ...forwardedMessage, message: this }) : null;
    this.payload = PayloadClass
      ? new PayloadClass({ ...payload, message: this })
      : new Payload({ ...payload, payloadType: 'Unknown', message: this });
    this.usersReactions = usersReactions;
    this.views = views;
  }

  toJSON() {
    const { groupId, clientTime, clientUuid, quotedMessage, payload } = this;

    return {
      groupId,
      clientTime,
      clientUuid,
      quotedMessageId: quotedMessage && quotedMessage.id,
      payload: payload.toJSON(),
    };
  }

  @computed
  get displayTime() {
    return this.isFake ? this.clientTime : this.serverTime;
  }

  @computed
  get unread() {
    return !this.isFake && this.serverTime > this.group.seenTs;
  }

  @computed
  get hasTranslation() {
    return Boolean(this.isLoadingTranslate || this.translatedPayload);
  }

  @action
  setMessageTime(expectedServerTime, serverTime) {
    this.expectedServerTime = expectedServerTime;
    if (!this.serverTime) {
      this.serverTime = serverTime;
    }
  }

  @computed
  get group() {
    return this.root.GroupsStore.getGroupById(this.groupId);
  }

  @computed
  get isP2PGroup() {
    return this.group && this.group.isP2P;
  }

  @computed
  get isBot() {
    return this.group && this.group.isBot;
  }

  @computed
  get isChatGroup() {
    return this.group && this.group.isChatGroup;
  }

  @computed
  get isPublicGroup() {
    return this.group && this.group.isChannel;
  }

  @computed
  get isSavedMessages() {
    return this.group && this.group.isSavedMessages;
  }

  @computed
  get isForwardToSavedMessages() {
    return Boolean(this.isSavedMessages && this.forward);
  }

  @computed
  get isSavedMessagesLeft() {
    return this.isForwardToSavedMessages;
  }

  @computed
  get isFake() {
    return this.clientUuid === this.id;
  }

  @computed
  get isFakeGroup() {
    return this.group && this.group.isFake;
  }

  @computed
  get isScheduled() {
    return this.group && this.group.isFake;
  }

  @computed
  get hasStatus() {
    return this.senderId === this.root.UsersStore.Me.id && !this.payload.isSystem;
  }

  @computed
  get isFailed() {
    return this.hasStatus && this.hasError;
  }

  @computed
  get isSeen() {
    return (
      this.hasStatus &&
      !this.isFake &&
      this.isP2PGroup &&
      this.group.opponentSeenTs >= this.serverTime
    );
  }

  @computed
  get isSending() {
    return this.hasStatus && this.isFake && !this.hasError;
  }

  @computed
  get isSent() {
    return this.hasStatus && !this.isFake;
  }

  @computed
  get mapKey() {
    if (this.fromMe) {
      // so that fake media messages do not blink after sending
      return this.clientUuid + '-' + this.expectedServerTime;
    }

    return this.id;
  }

  get senderUser() {
    if (this.isForwardToSavedMessages) {
      return this.forward.senderUser;
    }

    return this.root.UsersStore.getUserById(this.senderId);
  }

  @computed
  get fromMe() {
    if (this.isForwardToSavedMessages) {
      return false;
    }

    return this.senderId === this.root.UsersStore.Me.id;
  }

  @computed
  get isLeftPosition() {
    if (this.group.isChannel) {
      return true;
    }

    return !this.fromMe;
  }

  @computed
  get isSenderNameShow() {
    if (this.isForwardToSavedMessages) {
      return true;
    }

    if (this.group.isChannel && this.group.withMeInAdmins && this.fromMe) {
      return false;
    }

    if (this.group.isChannel && !this.fromMe && !this.group.withMeInAdmins) {
      return false;
    }

    if (this.group.isChannel && !this.fromMe) {
      return true;
    }

    return (
      !this.isPublicGroup &&
      !this.isP2PGroup &&
      !this.isBot &&
      this.isLocalFirst &&
      this.senderUser &&
      this.isLeftPosition
    );
  }

  @computed
  get isAvatarShow() {
    if (this.isP2PGroup) {
      return false;
    }

    if (this.isForwardToSavedMessages) {
      return true;
    }

    return !this.isPublicGroup && this.isLocalFirst && this.senderUser;
  }

  @computed
  get avatarUrl() {
    if (this.forward) {
      if (this.isForwardToSavedMessages) {
        if (this.forward.isFromChannel) {
          return this.forward.groupFrom?.avatarUrl;
        }

        return this.root.UsersStore.getUserById(this.forward.senderId)?.avatarUrl || null;
      }
    }

    if (this.isPublicGroup) {
      return this.group && this.group.avatarUrl;
    }

    return this.senderUser.avatarUrl;
  }

  @computed
  get avatarTitle() {
    if (this.forward) {
      if (this.isForwardToSavedMessages) {
        if (this.forward.isFromChannel) {
          return this.forward.avatarTitle;
        }

        return this.root.UsersStore.getUserById(this.forward.senderId)?.avatarTitle || null;
      }
    }

    if (this.group.isChannel && this.group.withMeInAdmins) {
      return this.senderUser.avatarTitle;
    }

    if (this.group.isChannel && !this.group.withMeInAdmins && !this.fromMe) {
      return this.group && this.group.avatarTitle;
    }

    if (this.isPublicGroup) {
      return this.group?.avatarTitle;
    }

    if (this.senderUser) {
      return this.senderUser.avatarTitle;
    }

    return '';
  }

  @computed
  get avatarColorIndex() {
    if (this.forward) {
      if (this.isForwardToSavedMessages) {
        if (this.forward.isFromChannel) {
          return this.forward.groupFrom?.avatarColorIndex || '0';
        }
        return this.root.UsersStore.getUserById(this.forward.senderId)?.avatarColorIndex || '0';
      }
    }

    if (this.isPublicGroup) {
      return this.group.avatarColorIndex;
    }

    return this.senderUser.avatarColorIndex;
  }

  @computed
  get lastMessageSenderName() {
    if (this.group.isChannel) {
      return null;
    }

    if (this.isForwardToSavedMessages) {
      return this.forward.avatarTitle;
    }

    if (this.senderUser) {
      return this.senderUser.avatarTitle;
    }

    return null;
  }

  @computed
  get isArticleDraft() {
    return this.group.draftsStore.data.has(this.id);
  }

  @action
  setId(id) {
    this.id = id;
  }

  @action
  setOptimisticReactions = (reactions) => {
    this.optimisticReactions = reactions;
  };

  @action
  setDeepLink = (deepLink) => {
    this.deepLink = deepLink;
  };

  @action
  translate = async () => {
    await Tasks.messaging.translateMessage({
      groupId: this.groupId,
      messageId: this.id,
    });
  };

  @action
  toggleTranslate = () => {
    this.isShowTranslate = !this.isShowTranslate;
  };

  @action
  setViews = (views) => {
    this.views = views;
  };
}
