import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { IconView } from 'APP/packages/UiKit/IconView/IconView';

import { LayoutImagePreview } from './LayoutImagePreview/LayoutImagePreview';
import usePresenter from './presenter';
import styles from './styles.m.css';

export const Layout = observer((props) => {
  const {
    className,
    imageUrl,
    graphicSize,
    Icon,
    title,
    text,
    messageId,
    isVideo = false,
    children,
    link,
    onClick,
  } = props;
  const presenter = usePresenter({ messageId, imageUrl, onClick });

  return (
    <div className={classNames(styles.container, className)}>
      {link ? (
        <a
          className={classNames(styles.focus, styles.link)}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {link}
        </a>
      ) : (
        <button
          aria-label="scroll to message"
          className={styles.focus}
          onClick={presenter.focusMessage}
        />
      )}
      {presenter.hasImage && (
        <LayoutImagePreview
          isVideo={isVideo}
          imageUrl={imageUrl}
          graphicSize={graphicSize}
        />
      )}
      <div className={styles.content}>
        <span className={styles.title}>{title}</span>
        <div className={styles.subtitle}>
          {Icon && (
            <IconView
              Icon={Icon}
              size="small"
              className={styles.icon}
            />
          )}
          {children ? children : text}
        </div>
      </div>
    </div>
  );
});
