export type TPlayIconTheme = '0';

export enum PlayIconType {
  Play = 'play',
  Pause = 'pause',
}

export enum PlayIconState {
  Error = 'error',
  Completed = 'completed',
  Loading = 'loading',
  Regular = 'regular',
  Infinity = 'infinity',
}
