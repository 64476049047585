import React, { FC } from 'react';

import { ImagePreview } from 'APP/components/ImagePreview/ImagePreview';
import { MINIATURE_PREVIEW_MAX_SIZE } from 'APP/constants/messages';
import { IGraphicSize } from 'APP/model/common/commonModel.types';
import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import IcPlay16 from 'ICONS/ic-play-16.svg';

import styles from './LayoutImagePreview.styles.m.css';

interface ILayoutImagePreviewProps {
  imageUrl: string;
  graphicSize?: IGraphicSize;
  isVideo: boolean;
}

export const LayoutImagePreview: FC<ILayoutImagePreviewProps> = (props) => {
  const { imageUrl, graphicSize, isVideo } = props;
  const imageSize = {
    width: graphicSize?.width || MINIATURE_PREVIEW_MAX_SIZE.width,
    height: graphicSize?.height || MINIATURE_PREVIEW_MAX_SIZE.height,
  };

  return (
    <ImagePreview
      className={styles.container}
      url={imageUrl}
      graphicSize={imageSize}
      maxWidth={MINIATURE_PREVIEW_MAX_SIZE.width}
      maxHeight={MINIATURE_PREVIEW_MAX_SIZE.height}
      needCropImage
    >
      {isVideo && (
        <div className={styles.videoIconContainer}>
          <IconView
            className={styles.video}
            Icon={IcPlay16}
            size={IconViewSizes.Small}
          />
        </div>
      )}
    </ImagePreview>
  );
};
