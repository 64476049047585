import { useAsObservableSource, useLocalStore } from 'mobx-react';
import { FC, useEffect, ElementType } from 'react';
import { useLocation } from 'react-router-dom';

import Tasks from 'APP/Tasks';
import { openPopup } from 'APP/Tasks/app/popup/popup';
import { useTranslation } from 'APP/packages/translations';
import useNavigateTo from 'APP/router/hooks/useNavigateTo';
import BlockIcon from 'ICONS/ic-block.svg';
import DeleteIcon from 'ICONS/ic-delete.svg';
import LogOutIcon from 'ICONS/ic-log-out.svg';
import VisibilityIcon from 'ICONS/ic-preview.svg';
import PrivacyIcon from 'ICONS/ic-privacy.svg';
import ReportIcon from 'ICONS/ic-report.svg';
import EditIcon from 'ICONS/ic-s-edit.svg';
import UsersIcon from 'ICONS/ic-users.svg';
import { ICommonPopupsProps } from 'MAIN/PopupManager/PopupManager.types';
import { ChangeSpaceVisibilityPopup } from 'MAIN/PopupManager/Popups/ChangeSpaceVisibilityPopup/ChangeSpaceVisibilityPopup';
import { EditSpacePopup } from 'MAIN/PopupManager/Popups/EditSpacePopup/EditSpacePopup';
import { ReportSpacePopup } from 'MAIN/PopupManager/Popups/ReportSpacePopup/ReportSpacePopup';
import { SpaceBlackList } from 'MAIN/PopupManager/Popups/SpaceBlackList/SpaceBlackList';
import { SpaceMembersPopup } from 'MAIN/PopupManager/Popups/SpaceMembersPopup/SpaceMembersPopup';
import { SpacePermissionsPopup } from 'MAIN/PopupManager/Popups/SpacePermissionsPopup/SpacePermissionsPopup';
import { useSpaceActions } from 'MAIN/hooks/space/useSpaceActions';
import { Popup } from 'STORE/App/Popups/Popup/Popup';
import { Space } from 'STORE/Spaces/Space';

interface ISpaceMenuItem {
  key: string;
  title: string;
  icon: ElementType;
  count?: number;
  onClick(): void;
}

interface ISpaceProfilePresenterParams {
  space: Space;
  popupInstance?: Popup;
}

interface ISpaceProfilePresenter {
  childPopup: Popup | null;
  menu: ISpaceMenuItem[];
  actions: ISpaceMenuItem[];
  canUpdateDeepLink: boolean;
  isShowOnboarding: boolean;
  onEditSpace(): void;
  onShowMembers(): void;
  onShowBlackList(): void;
  onShowPermissions(): void;
  onReportSpace(): void;
  onUpdateDeepLink(): Promise<void>;
  onChangeSpaceVisibility(): void;
  openChildPopup<T>(component: FC<ICommonPopupsProps<T>>, params: T): void;
}

export function useSpaceProfilePresenter({
  space,
  popupInstance,
}: ISpaceProfilePresenterParams): ISpaceProfilePresenter {
  const { t } = useTranslation();
  const navigateTo = useNavigateTo();
  const location = useLocation();
  const source = useAsObservableSource({ space, location, navigateTo });
  const spaceActions = useSpaceActions(space, popupInstance);

  const presenter = useLocalStore<ISpaceProfilePresenter>(() => ({
    childPopup: null,

    get menu(): ISpaceMenuItem[] {
      return [
        {
          key: 'members-space',
          title: t('space_members'),
          count: source.space.participantsCount,
          icon: UsersIcon,
          isVisible: source.space.isOwner || source.space.isAdmin,
          onClick: presenter.onShowMembers,
        },
        {
          key: 'balck-list-space',
          title: t('balck_list_title'),
          icon: BlockIcon,
          isVisible: source.space.isOwner,
          onClick: presenter.onShowBlackList,
        },
        {
          key: 'visibility-space',
          title: t('permissions_label'),
          icon: PrivacyIcon,
          isVisible: source.space.isPublic && (source.space.isOwner || source.space.isAdmin),
          onClick: presenter.onShowPermissions,
        },
      ].filter((item) => item.isVisible);
    },

    get actions(): ISpaceMenuItem[] {
      return [
        {
          key: 'edit-space',
          title: t('space_edit_space'),
          icon: EditIcon,
          isVisible: source.space.isOwner || source.space.isAdmin,
          onClick: presenter.onEditSpace,
        },
        {
          key: 'visibility-space',
          title: t('space_visibility_settings'),
          icon: VisibilityIcon,
          isVisible: !source.space.isGuest,
          onClick: presenter.onChangeSpaceVisibility,
        },
        {
          key: 'report-space',
          title: t('common_report'),
          icon: ReportIcon,
          isVisible: source.space.isMember,
          onClick: presenter.onReportSpace,
        },
        {
          key: 'leave-space',
          title: t('space_leave_space'),
          icon: LogOutIcon,
          isVisible: spaceActions.canLeave,
          onClick: spaceActions.onLeave,
        },
        {
          key: 'delete-space',
          title: t('space_delete_space'),
          icon: DeleteIcon,
          isVisible: spaceActions.canDelete,
          onClick: spaceActions.onDelete,
        },
      ].filter((item) => item.isVisible);
    },

    get canUpdateDeepLink(): boolean {
      return (source.space.isOwner || source.space.isAdmin) && source.space.isPublic;
    },

    get isShowOnboarding(): boolean {
      return source.space.isOwner;
    },

    onShowMembers(): void {
      presenter.openChildPopup(SpaceMembersPopup, { spaceId: source.space.id });
    },

    onShowBlackList(): void {
      presenter.openChildPopup(SpaceBlackList, { spaceId: source.space.id });
    },

    onShowPermissions(): void {
      presenter.openChildPopup(SpacePermissionsPopup, { spaceId: source.space.id });
    },

    onEditSpace(): void {
      presenter.openChildPopup(EditSpacePopup, { spaceId: source.space.id });
    },

    onReportSpace(): void {
      presenter.openChildPopup(ReportSpacePopup, { spaceId: source.space.id });
    },

    async onUpdateDeepLink(): Promise<void> {
      const result = await Tasks.app.showConfirm({
        text: t('new_deeplink_confirm_message'),
        primaryText: t('common_yes'),
        secondaryText: t('common_cancel'),
      });

      if (result) {
        await Tasks.space.updateDeepLink(source.space.id);
      }
    },

    onChangeSpaceVisibility(): void {
      presenter.openChildPopup(ChangeSpaceVisibilityPopup, { spaceId: source.space.id });
    },

    openChildPopup<T>(component: FC<ICommonPopupsProps<T>>, params: T): void {
      popupInstance?.hide();

      presenter.childPopup = openPopup(component, {
        params,
        onClose: () => popupInstance?.close(),
        onBack: () => popupInstance?.show(),
      });
    },
  }));

  useEffect(() => {
    return () => presenter.childPopup?.close();
  }, []);

  return presenter;
}
