import classNames from 'classnames';
import React, { FC, MouseEventHandler } from 'react';

import styles from 'UIKIT/PlayIcon/PlayIcon.styles.m.css';

import { PlayIconState, PlayIconType, TPlayIconTheme } from './PlayIcon.types';
import { PlayIconComplete } from './PlayIconComplete/PlayIconComplete';
import { PlayIconError } from './PlayIconError/PlayIconError';
import { PlayIconLoading } from './PlayIconLoading/PlayIconLoading';
import { PlayIconLoadingInfinity } from './PlayIconLoadingInfinity/PlayIconLoadingInfinity';
import { PlayIconRegular } from './PlayIconRegular/PlayIconRegular';

export * from './PlayIcon.types';

interface IPlayIconProps {
  className?: string;
  state?: PlayIconState;
  theme?: TPlayIconTheme;
  progress?: number;
  onClick?: MouseEventHandler<SVGSVGElement | SVGGElement>;
  onCancel?: MouseEventHandler<SVGSVGElement | SVGGElement>;
  type: PlayIconType;
}

export const PlayIcon: FC<IPlayIconProps> = (props) => {
  const {
    type = PlayIconType.Play,
    theme = '0',
    progress,
    state = PlayIconState.Regular,
    onClick,
    onCancel,
    className,
  } = props;

  const commonProps = { type, theme, onClick, onCancel, progress, className };

  const getState = () => {
    switch (state) {
      case PlayIconState.Error: {
        return <PlayIconError {...commonProps} />;
      }
      case PlayIconState.Completed: {
        return <PlayIconComplete {...commonProps} />;
      }
      case PlayIconState.Loading: {
        return <PlayIconLoading {...commonProps} />;
      }
      case PlayIconState.Infinity: {
        return <PlayIconLoadingInfinity {...commonProps} />;
      }
      default: {
        return <PlayIconRegular {...commonProps} />;
      }
    }
  };

  return (
    <svg
      style={{ strokeDashoffset: 100 - (progress || 0) / 2.765 }}
      onClick={onClick}
      className={classNames(styles.icon, className)}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {getState()}
      {type === PlayIconType.Play ? (
        <path
          d="M20.8113 17.181L30.4717 23.0723C31.1761 23.5716 31.1761 24.5701 30.4717 24.9695L20.8113 30.8608C20.0063 31.2602 19 30.761 19 29.8623V18.1795C19 17.2808 20.0063 16.6817 20.8113 17.181Z"
          fill="white"
        />
      ) : (
        <path
          d="M20 31C21.1 31 22 30.1 22 29V19C22 17.9 21.1 17 20 17C18.9 17 18 17.9 18 19V29C18 30.1 18.9 31 20 31ZM26 19V29C26 30.1 26.9 31 28 31C29.1 31 30 30.1 30 29V19C30 17.9 29.1 17 28 17C26.9 17 26 17.9 26 19Z"
          fill="white"
        />
      )}
    </svg>
  );
};
