import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import { Layout } from '../components/Layout';
import styles from './styles.m.scss';

const Text = ({ message, title, onClick }) => {
  const { text, linkPreview } = message.payload;

  if (!message) {
    return null;
  }

  if (linkPreview) {
    return (
      <Layout
        imageUrl={linkPreview.image?.url}
        title={title}
        text={text}
        messageId={message.id}
        onClick={onClick}
      />
    );
  }

  return (
    <Layout
      title={title}
      messageId={message.id}
      onClick={onClick}
    >
      <TextRenderer
        isPlainText
        className={styles.text}
      >
        {text}
      </TextRenderer>
    </Layout>
  );
};

export default observer(Text);
