import React from 'react';

import { useTranslation } from 'APP/packages/translations';

import { Layout } from '../components/Layout';
import usePresenter from './presenter';

const Advertisement = ({ message, title, onClick }) => {
  const imageUrl = usePresenter({ message });
  const { t } = useTranslation();

  if (!message) {
    return null;
  }

  return (
    <Layout
      imageUrl={imageUrl}
      title={title}
      text={t('calls:common_advertising')}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Advertisement;
