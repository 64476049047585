import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { List } from 'APP/components/List/List';
import { SharedDataType } from 'APP/constants/sharedData';
import { Loader } from 'UIKIT/Loader/Loader';

import { useSharedDataPresenter } from '../SharedData.presenter';
import styles from '../SharedData.styles.m.css';
import { EmptyContentStub } from '../components/EmptyContentStub/EmptyContentStub';
import { GroupByDateItems } from '../components/GroupByDateItems';
import { VoiceItem } from './VoiceItem/VoicesItem';

export const Voices = observer(({ className, groupId }) => {
  const presenter = useSharedDataPresenter({
    groupId,
    type: SharedDataType.Voices,
  });

  return (
    <List
      isLoading={presenter.isLoading}
      className={classNames(styles.container, className)}
      data={presenter.dateList}
      hasMore={presenter.hasMore}
      onEndReached={presenter.loadMore}
      keyExtractor={(date) => date}
      renderItem={(date) => (
        <GroupByDateItems
          key={date}
          title={date}
          data={presenter.groupFilesByDate[date]}
          groupId={groupId}
          sharedDataType={SharedDataType.Voices}
          renderItem={(item) => (
            <VoiceItem
              key={`${item.id}_${item.messageId}`}
              {...item}
            />
          )}
        />
      )}
      SpinnerComponent={
        <div className={styles.loader}>
          <Loader color={'base'} />
        </div>
      }
      ListEmptyComponent={<EmptyContentStub />}
    />
  );
});
