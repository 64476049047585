import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler } from 'react';

import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import { useTranslation } from 'APP/packages/translations';
import { ChatMessage } from 'APP/store/Messages/Message/ChatMessage/ChatMessage';
import DeleteIcon from 'ICONS/ic-delete-16.svg';

import { Layout } from '../components/Layout';
import styles from './DeletedByMePayload.styles.m.scss';

interface IDeletedByPayloadProps {
  message: ChatMessage;
  title: string;
  onClick?: MouseEventHandler;
}

export const DeletedByMePayload: FC<IDeletedByPayloadProps> = observer(
  ({ message, title, onClick }) => {
    const { t } = useTranslation();

    return (
      <Layout
        title={title}
        messageId={message.id}
        onClick={onClick}
      >
        <div className={styles.text}>
          <IconView
            Icon={DeleteIcon}
            className={styles.icon}
            size={IconViewSizes.Small}
          />
          {t('common_message_deleted')}
        </div>
      </Layout>
    );
  }
);
