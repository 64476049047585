import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { useFileDownloadStatePresenter } from 'APP/hooks/useFileDownloadState/useFileDownloadState';
import { PayloadType } from 'APP/model/message/messageModel.types';

import { AllDownloadsItemActions } from '../AllDownloadsItemActions/AllDownloadsItemActions';
import { AllDownloadsAudioItem } from './AllDownloadsAudioItem/AllDownloadsAudioItem';
import { AllDownloadsFileItem } from './AllDownloadsFileItem/AllDownloadsFileItem';
import styles from './AllDownloadsItem.styles.m.css';
import { AllDownloadsMediaItem } from './AllDownloadsMediaItem/AllDownloadsMediaItem';

interface IAllDownloadsItemProps {
  payloadId: string;
  groupId: string;
  messageId: string;
}

type TPayloadType =
  | PayloadType.File
  | PayloadType.AudioMessage
  | PayloadType.VoiceMessage
  | PayloadType.Video
  | PayloadType.Image;

const MAP_COMPONENTS = {
  [PayloadType.File]: AllDownloadsFileItem,

  [PayloadType.AudioMessage]: AllDownloadsAudioItem,
  [PayloadType.VoiceMessage]: AllDownloadsAudioItem,

  [PayloadType.Image]: AllDownloadsMediaItem,
  [PayloadType.Video]: AllDownloadsMediaItem,
};

export const AllDownloadsItem: FC<IAllDownloadsItemProps> = observer((props) => {
  const { payloadId, groupId, messageId } = props;

  const { payload, hasError, onRemove, onDownload } = useFileDownloadStatePresenter({
    payloadId,
    groupId,
    messageId,
  });

  if (!payload) {
    return null;
  }

  const Component = MAP_COMPONENTS[payload.payloadType as TPayloadType];

  return (
    <div className={styles.container}>
      <Component
        className={styles.downloadItem}
        payloadId={payloadId}
        groupId={groupId}
        messageId={messageId}
      />
      {hasError && (
        <AllDownloadsItemActions
          onRemove={onRemove}
          onRetry={onDownload}
          className={styles.actions}
        />
      )}
    </div>
  );
});
