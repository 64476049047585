import React from 'react';

import IcContact16 from 'ICONS/ic-contact-16.svg';

import { Layout } from '../components/Layout';

const Contact = ({ message, title, onClick }) => {
  return (
    <Layout
      Icon={IcContact16}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Contact;
