import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { ForwardMessage } from '../components/ForwardMessage/ForwardMessage';
import Quote from '../components/Quote';
import { SenderName } from '../components/SenderName/SenderName';
import StatusBar from '../components/StatusBar';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import styles from './ContactBubble.styles.m.scss';

export const ContactBubble = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;
  const { payload } = message;

  const statusBarClasses = classNames({
    [styles.statusBarDefault]: !payload.showStartChatButton,
    [styles.statusBarAfterButton]: payload.showStartChatButton,
  });

  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      <SenderName
        message={message}
        className={styles.senderName}
      />
      {message.quotedMessage && (
        <Quote
          className={styles.quote}
          message={message.quotedMessage}
        />
      )}
      {message.forward && !message.isSavedMessagesLeft && (
        <ForwardMessage
          className={styles.forward}
          message={message}
        />
      )}
      <div
        className={styles.content}
        data-test="contact-message"
      >
        {children}
        <StatusBar
          className={statusBarClasses}
          message={message}
        />
      </div>
      <ThreadButton
        message={message}
        className={styles.threadButton}
      />
    </MessageLayout>
  );
});
