import { useAsObservableSource, useLocalStore } from 'mobx-react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';

export default ({ messageId, imageUrl, onClick }) => {
  const source = useAsObservableSource({ messageId, imageUrl });

  const presenter = useLocalStore(() => ({
    get group() {
      return Entities.GroupsStore.getGroupById(presenter.groupId);
    },

    get groupId() {
      return Entities.GroupsStore.activeGroupId;
    },

    get hasImage() {
      return Boolean(source.imageUrl);
    },

    focusMessage() {
      if (!presenter.groupId || !source.messageId) {
        return null;
      }

      Tasks.messaging.focusMessage({
        groupId: presenter.groupId,
        messageId: source.messageId,
      });

      onClick && onClick();
    },
  }));

  return presenter;
};
