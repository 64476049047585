import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AudioComponent } from 'APP/constants/audio';

import { ArticleAudioPlayer } from './ArticleAudioPlayer/ArticleAudioPlayer';
import { useAudioPlayerPresenter } from './AudioPlayer.presenter';
import { MessageAudioPlayer } from './MessageAudioPlayer/MessageAudioPlayer';
import { SharedAudioPlayer } from './SharedAudioPlayer/SharedAudioPlayer';
import { SharedVoicePlayer } from './SharedVoicePlayer/SharedVoicePlayer';

const component = {
  [AudioComponent.Article]: ArticleAudioPlayer,
  [AudioComponent.Message]: MessageAudioPlayer,
  [AudioComponent.SharedAudio]: SharedAudioPlayer,
  [AudioComponent.SharedVoice]: SharedVoicePlayer,
};

interface IAudioPlayerProps {
  className?: string;
  fileName: string;
  size: number;
  duration: string;
  serverTime?: number;
  componentName: AudioComponent;
  messageId: string;
  groupId: string;
  payloadId: string;
  clientUuid: string;
  uploadProgress: number;
  hasError: boolean;
  cancelUploadAndDeleteMessage(): Promise<void>;
}

export const AudioPlayer: FC<IAudioPlayerProps> = observer((props) => {
  const {
    fileName,
    size,
    duration,
    serverTime = 0,
    className,
    componentName = AudioComponent.Message,
    messageId,
    groupId,
    payloadId,
    clientUuid,
    uploadProgress,
    hasError,
    cancelUploadAndDeleteMessage,
  } = props;

  const Component = component[componentName] || null;

  const { isPlaying, isActiveTrack, progress, formattedDuration, onTogglePlay } =
    useAudioPlayerPresenter({ groupId, messageId, payloadId, clientUuid, duration });

  if (!Component) {
    return null;
  }

  return (
    <Component
      className={className}
      fileName={fileName}
      size={size}
      duration={formattedDuration}
      serverTime={serverTime}
      isPlaying={isPlaying}
      isActiveTrack={isActiveTrack}
      progress={progress}
      messageId={messageId}
      groupId={groupId}
      payloadId={payloadId}
      uploadProgress={uploadProgress}
      hasError={hasError}
      cancelUploadAndDeleteMessage={cancelUploadAndDeleteMessage}
      onTogglePlay={onTogglePlay}
    />
  );
});
