import React from 'react';

import { Layout } from '../components/Layout';

const Location = ({ message, title, onClick }) => {
  return (
    <Layout
      imageUrl={`${message.payload.url}&size=80x80`}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Location;
