import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { AudioPlayer } from 'UIKIT/Media/AudioPlayer/AudioPlayer';

import { useMiniAudioPlayerPresenter } from './MiniAudioPlayer.presenter';
import styles from './MiniAudioPlayer.styles.m.css';

export const MiniAudioPlayer: FC = observer(() => {
  const presenter = useMiniAudioPlayerPresenter();

  if (!presenter.isOpened) {
    return null;
  }

  return (
    <AudioPlayer
      className={styles.audioPlayer}
      autoPlay={true}
      isPlaying={presenter.isPlaying}
      track={presenter.track}
      config={presenter.config}
      onConfigUpdate={presenter.onConfigUpdate}
      isShowPlayList={presenter.isShowPlayList}
      playList={presenter.playList}
      isShowOrder={presenter.isShowOrder}
      isShowRepeat={presenter.isShowRepeat}
      progress={presenter.newProgress}
      progressPercentage={presenter.newProgressPercentage}
      onChangeTrack={presenter.onChangeTrack}
      onProgress={presenter.onProgress}
      onTogglePlay={presenter.onTogglePlay}
      onTitleClick={presenter.onTitleClick}
      onClose={presenter.onClose}
    />
  );
});
