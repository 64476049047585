import { useLocalStore } from 'mobx-react';
import { useEffect } from 'react';

import Tasks from 'APP/Tasks';
import { AudioSourceType } from 'APP/constants/app';
import { PayloadType } from 'APP/model/message/messageModel.types';
import { useTranslation } from 'APP/packages/translations';
import Entities from 'STORE';
import { Group } from 'STORE/Groups/Group';
import { MiniAudioPlayer } from 'STORE/MiniAudioPlayer/MiniAudioPlayer';
import { IAudioPlayerConfig, IAudioPlayerTrack } from 'UIKIT/Media/AudioPlayer/AudioPlayer.types';

import { getVoiceMessageDescription } from './MiniAudioPlayer.utils';

interface ITrackPayload {
  groupId: string;
  messageId: string;
}

interface IMiniAudioPlayer {
  groupId: string | null;
  player: MiniAudioPlayer;
  track: IAudioPlayerTrack<ITrackPayload> | undefined;
  playList: IAudioPlayerTrack<ITrackPayload>[];
  group: Group | null;
  sharedDataItems: any[];
  isPlaying: boolean;
  isOpened: boolean;
  isShowPlayList: boolean;
  isShowOrder: boolean;
  isShowRepeat: boolean;
  isVoicePlayList: boolean;
  newProgress: number | null;
  newProgressPercentage: number | null;
  config: Partial<IAudioPlayerConfig>;
  removeSource: (() => void) | null;
  initialize(): void;
  onConfigUpdate(config: Partial<IAudioPlayerConfig>): void;
  onChangeTrack(track: IAudioPlayerTrack<ITrackPayload>): void;
  onProgress(progress: number, progressPercentage: number): void;
  onTogglePlay(isPlaying: boolean): void;
  onTitleClick(): Promise<void>;
  onClose(): void;
  onRemoveAudioSource(): void;
  onSharedItemsChange(): void;
}

export function useMiniAudioPlayerPresenter(): IMiniAudioPlayer {
  const { t } = useTranslation();

  const presenter = useLocalStore<IMiniAudioPlayer>(() => ({
    track: undefined,
    groupId: null,
    isShowOrder: false,
    isShowRepeat: false,
    removeSource: null,

    config: {
      speed: 1,
    },

    get group(): Group | null {
      return presenter.player.groupId
        ? Entities.GroupsStore.getGroupById(presenter.player.groupId)
        : null;
    },

    get isVoicePlayList(): boolean {
      if (!presenter.player.messageId) {
        return false;
      }
      return (
        presenter.group?.messagesStore.getMessageById(presenter.player.messageId)?.payload
          .payloadType === PayloadType.VoiceMessage
      );
    },

    get isShowPlayList(): boolean {
      return !presenter.isVoicePlayList;
    },

    initialize(): void {
      const group = presenter.player.groupId
        ? Entities.GroupsStore.getGroupById(presenter.player.groupId)
        : null;

      if (!group) {
        return;
      }

      presenter.track = presenter.playList.find((track) => track.id === presenter.player.payloadId);
    },

    get sharedDataItems(): any[] {
      const group = presenter.group;

      if (!group) {
        return [];
      }

      return (presenter.isVoicePlayList ? group.sharedData.voices : group.sharedData.audio).items;
    },

    get playList(): IAudioPlayerTrack<ITrackPayload>[] {
      const isVoicePayload = presenter.isVoicePlayList;

      const playList = presenter.sharedDataItems.map((item: any) => {
        let title: string;

        if (isVoicePayload) {
          title = presenter.group?.isChannel
            ? presenter.group?.avatarTitle
            : item.payload.message.avatarTitle;
        } else {
          title = item.payload.fileName;
        }

        // TODO: solve this problem inside AudioPlayer component
        const id = item.payload.message.clientUuid || item.id;
        const url = item.payload.userFileBlobUrl || `${item.payload.url}?id=${id}`;

        return {
          id,
          url,
          title,
          description: isVoicePayload
            ? getVoiceMessageDescription(item.serverTime, t('statistics_voice_at'))
            : '',
          size: parseInt(item.payload.fileSize, 10),
          duration: parseInt(item.payload.duration, 10) / 1000,
          payload: {
            groupId: item.groupId,
            messageId: item.messageId,
          },
        };
      });

      return playList.reverse();
    },

    get player(): MiniAudioPlayer {
      return Entities.miniAudioPlayer;
    },

    get isOpened(): boolean {
      return presenter.player.isOpened;
    },

    get isPlaying(): boolean {
      return presenter.player.isPlaying;
    },

    get newProgress(): number | null {
      return presenter.player.newProgress;
    },

    get newProgressPercentage(): number | null {
      return presenter.player.newProgressPercentage;
    },

    onConfigUpdate(config: Partial<IAudioPlayerConfig>): void {
      presenter.config = {
        ...presenter.config,
        ...config,
      };
    },

    onProgress(progress: number, progressPercentage: number): void {
      presenter.player.setProgress(progress, progressPercentage);
    },

    onTogglePlay(isPlaying: boolean): void {
      presenter.player.setIsPlaying(isPlaying);

      if (isPlaying && !presenter.removeSource) {
        presenter.removeSource = Tasks.app.audioSource.setCurrentSource(AudioSourceType.Audio, () =>
          presenter.onTogglePlay(false)
        );
      }

      if (!isPlaying) {
        presenter.onRemoveAudioSource();
      }
    },

    onChangeTrack(track: IAudioPlayerTrack<ITrackPayload>): void {
      if (track.payload?.messageId) {
        presenter.player.setMessageId(track.payload.messageId);
      }
      presenter.player.setPayloadId(track.id);
    },

    async onTitleClick(): Promise<void> {
      await Tasks.group.setActiveGroup({ groupId: presenter.player.groupId });
      Tasks.messaging.focusMessage({
        groupId: presenter.player.groupId,
        messageId: presenter.player.messageId,
      });
    },

    onClose(): void {
      presenter.groupId = null;
      presenter.player.reset();
    },

    onRemoveAudioSource(): void {
      if (presenter.removeSource) {
        presenter.removeSource();
        presenter.removeSource = null;
      }
    },

    onSharedItemsChange(): void {
      if (!presenter.player.isOpened || !presenter.track) {
        return;
      }

      const isMessageRemoved = !presenter.sharedDataItems.find(
        (item) =>
          item.id === presenter.track?.id || item.payload.message.clientUuid === presenter.track?.id
      );

      if (isMessageRemoved) {
        presenter.onClose();
      }
    },
  }));

  useEffect(() => {
    if (presenter.player.isOpened) {
      presenter.initialize();
    }
  }, [presenter.player.groupId, presenter.player.messageId, presenter.player.payloadId]);

  useEffect(() => {
    return () => {
      presenter.onRemoveAudioSource();
      presenter.player.reset();
    };
  }, []);

  useEffect(() => {
    presenter.onSharedItemsChange();
  }, [presenter.sharedDataItems]);

  return presenter;
}
