import { useCallback, useEffect, useMemo, useRef } from 'react';

import Tasks from 'APP/Tasks';
import Entities from 'STORE';

interface IVoicePresenterParams {
  groupId: string;
  messageId: string;
  payloadId: string;
  clientUuid: string;
  duration: string;
  isLoading: boolean;
}

interface IVoicePresenter {
  isPlaying: boolean;
  isActiveTrack: boolean;
  formattedDuration: number;
  progress: number;
  progressPercentage: number;
  onTogglePlay(): void;
  onChangeProgress(progress: number): void;
}

export function useVoicePresenter({
  groupId,
  messageId,
  payloadId,
  clientUuid,
  duration,
  isLoading,
}: IVoicePresenterParams): IVoicePresenter {
  const audioPlayer = Entities.miniAudioPlayer;
  const isLoadingRef = useRef(isLoading);

  const onChangeProgress = useCallback(
    (progressPercentage) => {
      if (isLoadingRef.current) {
        return;
      }

      if (audioPlayer.payloadId === payloadId || audioPlayer.payloadId === clientUuid) {
        audioPlayer.updateProgressPercentage(progressPercentage);
      } else {
        Tasks.miniAudioPlayer.openAudioPlayer(groupId, messageId, clientUuid || payloadId);
      }
    },
    [groupId, messageId, payloadId, clientUuid, audioPlayer.payloadId]
  );

  const onTogglePlay = useCallback(() => {
    if (payloadId === audioPlayer.payloadId || clientUuid === audioPlayer.payloadId) {
      audioPlayer.setIsPlaying(!audioPlayer.isPlaying);
    } else {
      Tasks.miniAudioPlayer.openAudioPlayer(groupId, messageId, clientUuid || payloadId);
    }
  }, [groupId, messageId, payloadId, clientUuid]);

  const isPlaying = useMemo(() => {
    return (
      audioPlayer.isPlaying &&
      (audioPlayer.payloadId === payloadId || audioPlayer.payloadId === clientUuid)
    );
  }, [audioPlayer.isPlaying, audioPlayer.payloadId, payloadId, clientUuid]);

  const isActiveTrack = useMemo(() => {
    return audioPlayer.payloadId === payloadId || audioPlayer.payloadId === clientUuid;
  }, [audioPlayer.payloadId, payloadId, clientUuid]);

  const formattedDuration = useMemo(() => {
    return parseInt(duration, 10) / 1000;
  }, [duration]);

  const progressPercentage = useMemo(() => {
    return isActiveTrack ? audioPlayer.progressPercentage : 0;
  }, [audioPlayer.progressPercentage, isActiveTrack]);

  useEffect(() => {
    isLoadingRef.current = isLoading;
  }, [isLoading]);

  return {
    isPlaying,
    isActiveTrack,
    progress: audioPlayer.progress,
    progressPercentage,
    formattedDuration,
    onTogglePlay,
    onChangeProgress,
  };
}
