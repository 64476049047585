import { observer } from 'mobx-react';
import React from 'react';

import { AudioPlayer } from 'APP/components/AudioPlayer/AudioPlayer';
import { AudioComponent } from 'APP/constants/audio';

import { useVoicesItemPresenter } from './VoicesItem.presenter';

export const VoiceItem = observer((props) => {
  const { id: payloadId, payload, groupId, messageId, serverTime } = props;
  const { url, duration, message } = payload;
  const presenter = useVoicesItemPresenter({ groupId, messageId });

  if (!presenter.message) {
    return null;
  }

  return (
    <AudioPlayer
      src={url}
      fileName={presenter.title}
      serverTime={serverTime}
      duration={duration}
      componentName={AudioComponent.SharedVoice}
      messageId={messageId}
      groupId={groupId}
      payloadId={payloadId}
      clientUuid={message.clientUuid}
    />
  );
});
