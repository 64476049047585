import dateService from 'APP/packages/date';

export function getVoiceMessageDescription(date: Date, prefix: string): string {
  const formattedDate = getMessageDate(date);

  return formattedDate ? `${prefix} ${formattedDate}` : '';
}

export function getMessageDate(date: Date): string | null {
  const minutesHours = dateService.format(date, {
    hour: 'numeric',
    minute: 'numeric',
  });

  if (dateService.isToday(date)) {
    return minutesHours;
  }

  const monthYear = dateService.format(date, {
    year: !dateService.isLastYear(date) ? 'numeric' : undefined,
    month: 'short',
    day: 'numeric',
  });

  return `${monthYear} ${minutesHours}`;
}
