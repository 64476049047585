import { observer } from 'mobx-react';
import React from 'react';

import { AddKeyPressHandler } from 'APP/packages/keyBoardHandler';
import IcClose16 from 'ICONS/ic-close-16.svg';
import { Layout } from 'MAIN/ChatView/components/MiniatureMessage/Payloads/components/Layout';
import { FAB } from 'UIKIT/FAB/FAB';

import usePresenter from './presenter';
import styles from './styles.m.css';

const LinkPreview = () => {
  const presenter = usePresenter();

  if (!presenter.isShowLinkPreview) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.preview}>
        <AddKeyPressHandler onEsc={presenter.closePreview} />
        <Layout
          className={styles.previewContainer}
          imageUrl={presenter.linkPreviewData.image?.url}
          title={<p className={styles.title}>{presenter.linkPreviewData.domain}</p>}
          text={presenter.linkPreviewData.title}
          link={presenter.linkPreviewData.link}
        />
        <FAB
          Icon={IcClose16}
          size="10"
          iconSize="0"
          theme="12"
          onClick={presenter.closePreview}
        />
      </div>
    </div>
  );
};

export default observer(LinkPreview);
