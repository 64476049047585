import Tasks from 'APP/Tasks';
import { PayloadType } from 'APP/model/message/messageModel.types';
import api from 'APP/packages/api';
import dateService from 'APP/packages/date';
import Entities from 'APP/store';

const LINE_LENGTH = 30;

const textFieldName = {
  [PayloadType.RichText]: 'text',
  [PayloadType.File]: 'fileName',
  [PayloadType.AudioMessage]: 'fileName',
  [PayloadType.Image]: 'comment',
  [PayloadType.Video]: 'comment',
  [PayloadType.Album]: 'comment',
  [PayloadType.Article]: 'title',
};

const getFormatDate = (date) => {
  let options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };
  if (dateService.isLastYear(date)) {
    options = {
      month: 'short',
      day: 'numeric',
    };
  }
  if (dateService.isLastWeek(date)) {
    options = {
      weekday: 'short',
    };
  }
  if (dateService.isToday(date)) {
    options = {
      hour: 'numeric',
      minute: 'numeric',
    };
  }
  return dateService.format(date, options);
};

const getNextChunk = (text, offset, selections, index) => {
  let before = text;
  let select = '';
  let after = null;
  if (selections[index]) {
    const { start, end } = selections[index];
    before = text.slice(0, start - offset);
    select = text.slice(start - offset, end - offset);
    after = getNextChunk(text.slice(end - offset), end, selections, index + 1);
  }
  return {
    before,
    select,
    after,
  };
};

const getTextChunk = (text, selections = []) => {
  let { start = 0, end = 0 } = selections[0] || {};
  let before = Math.max(0, (end + start) / 2 - LINE_LENGTH / 2);
  let after = Math.min(text.length, (end + start) / 2 + LINE_LENGTH / 2);
  if (before === 0) {
    after = Math.min(text.length, before + LINE_LENGTH);
  }
  if (after === text.length) {
    before = Math.max(0, after - LINE_LENGTH);
  }
  start = Math.max(start, before);
  end = Math.min(end, after);

  return {
    before: `${before > 0 ? '...' : ''}${text.slice(before, start)}`,
    select: text.slice(start, end),
    after: getNextChunk(text.slice(end), end, selections, 1),
  };
};

const getSenderData = (group, message) => {
  if (group.isChannel) {
    return {
      avatarUrl: group.avatarUrl,
      avatarTitle: group.avatarTitle,
      avatarColorIndex: group.avatarColorIndex,
      verified: group.verified,
    };
  }
  let sender = Entities.UsersStore.getUserById(message.senderId.toString());

  if (group.isSavedMessages && message.forwardedMessage) {
    // if redirected from channel
    const forwardGroup = Entities.GroupsStore.getGroupById(message.forwardedMessage.groupId);
    if (forwardGroup && forwardGroup.isChannel) {
      sender = forwardGroup;
    } else {
      sender = Entities.UsersStore.getUserById(message.forwardedMessage.senderId.toString());
    }
  }
  if (sender) {
    return {
      avatarUrl: sender.avatarUrl,
      avatarTitle: sender.avatarTitle,
      avatarColorIndex: sender.avatarColorIndex,
      verified: sender.verified,
      usernameBadge: sender.usernameBadge,
    };
  }
};

const getMessage = (group, result) => {
  const fieldName = textFieldName[result.message.payload.payloadType];
  const text = fieldName ? result.message.payload[fieldName] : '';
  return {
    id: result.message.id,
    textChunk: getTextChunk(text, result.highlights),
    payloadType: result.message.payload.payloadType,
    text,
    date: getFormatDate(+result.message.serverTime),
    ...getSenderData(group, result.message),
  };
};

export default async ({ value, cursor, groupId }) => {
  try {
    const { results, cursor: nextCursor } = await api.messaging.search({
      text: value,
      limit: 100,
      groupId,
      cursor,
    });

    if (results.length > 0) {
      await Tasks.relationships.loadUnknownDataFromMessages({
        messages: results.map(({ message }) => message),
      });
    }

    const group = Entities.GroupsStore.getGroupById(groupId.toString());
    const mapMessage = getMessage.bind(null, group);

    return {
      results: results.map(mapMessage),
      cursor: nextCursor,
      error: null,
    };
  } catch (error) {
    console.error('messagesSearch', error);
    return { error };
  }
};
