import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';
import ReactDOM from 'react-dom';
import Draggable from 'react-draggable';

import { VideoPlayer } from 'UIKIT/Media/VideoPlayer/VideoPlayer';

import { useGlobalVideoPlayerPresenter } from './GlobalVideoPlayer.presenter';
import styles from './GlobalVideoPlayer.styles.m.css';

const PREVENT_DRAG_SELECTORS = 'button, [role="progressbar"]';

export const GlobalVideoPlayer = observer(() => {
  const presenter = useGlobalVideoPlayerPresenter();

  const containerClass = classNames(styles.container, {
    [styles.mini]: presenter.isMini,
    [styles.hidden]: presenter.isHidden,
  });

  if (!presenter.url) {
    return null;
  }

  return ReactDOM.createPortal(
    <Draggable
      cancel={PREVENT_DRAG_SELECTORS}
      bounds={'body'}
      disabled={!presenter.isDraggable}
      position={!presenter.isDraggable ? { x: 0, y: 0 } : presenter.position}
      onStop={presenter.onDragEnd}
    >
      <div className={containerClass}>
        <VideoPlayer
          url={presenter.url}
          previewUrl={presenter.previewUrl}
          autoPlay={true}
          isPlaying={presenter.isPlaying}
          isFullScreenMode={presenter.isFullScreenMode}
          type={presenter.type}
          config={presenter.playerConfig}
          onTogglePlay={presenter.onTogglePlay}
          onToggleFullScreenMode={presenter.onToggleFullScreenMode}
          onConfigUpdate={presenter.setPlayerConfig}
          onShowMiniPlayer={presenter.canShowMiniPlayer ? presenter.onShowMiniPlayer : undefined}
          onExpandMiniPlayer={
            presenter.canExpandMiniPlayer ? presenter.onExpandMiniPlayer : undefined
          }
          onCloseMiniPlayer={presenter.onCloseMiniPlayer}
        />
      </div>
    </Draggable>,
    presenter.container
  );
});
