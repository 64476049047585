import React from 'react';

import { AudioPlayer } from 'APP/components/AudioPlayer/AudioPlayer';
import { AudioComponent } from 'APP/constants/audio';

const Audio = ({ message }) => {
  const {
    id: payloadId,
    fileName,
    fileSize,
    duration,
    uploadProgress,
    cancelUploadAndDeleteMessage,
  } = message.payload;

  return (
    <AudioPlayer
      size={fileSize}
      fileName={fileName}
      duration={duration}
      componentName={AudioComponent.Article}
      messageId={message.messageId}
      groupId={message.groupId}
      payloadId={payloadId || message.id}
      uploadProgress={uploadProgress}
      cancelUploadAndDeleteMessage={cancelUploadAndDeleteMessage}
      hasError={message.hasError}
    />
  );
};

export default Audio;
