import { RefObject, useCallback, useEffect, useState } from 'react';

export interface IMediaPlayerProgressBuffer {
  start: number;
  end: number;
}

interface IMediaPlayerProgressOptions {
  url: string | null;
  mediaRef: RefObject<HTMLMediaElement>;
  newProgress?: number | null;
  newProgressPercentage?: number | null;
  onProgress?(progress: number, progressPercentage: number): void;
}

interface IMediaPlayerProgress {
  progress: number;
  progressMax: number;
  progressBuffer: IMediaPlayerProgressBuffer[];
  setProgress(progress: number): void;
  updateProgressMax(): void;
  updateProgress(): void;
  updateProgressBuffered(): void;
}

export function useMediaPlayerProgress({
  url,
  mediaRef,
  newProgress = null,
  newProgressPercentage = null,
  onProgress,
}: IMediaPlayerProgressOptions): IMediaPlayerProgress {
  const [progress, setProgressState] = useState(0);
  const [progressMax, setProgressMax] = useState(0);
  const [progressBuffer, setProgressBuffer] = useState<IMediaPlayerProgressBuffer[]>([]);

  const setProgress = useCallback((progress) => {
    if (mediaRef.current) {
      mediaRef.current.currentTime = progress;
    }
    setProgressState(progress);
  }, []);

  const updateProgress = useCallback(() => {
    const progress = mediaRef.current?.currentTime || 0;

    updateProgressMax();
    setProgressState(progress);
    updateProgressBuffered();

    const progressPercentage = progress / (mediaRef.current?.duration || 0);
    onProgress?.(progress, isNaN(progressPercentage) ? 0 : progressPercentage);
  }, []);

  const updateProgressMax = useCallback(() => {
    const duration = mediaRef.current?.duration || NaN;
    setProgressMax(!isNaN(duration) ? duration : 0);
  }, []);

  const updateProgressBuffered = useCallback(() => {
    if (!mediaRef.current) {
      return;
    }

    const buffered = mediaRef.current.buffered;
    const buffer = [];

    for (let i = 0; i < buffered.length; i++) {
      buffer.push({
        start: buffered.start(i),
        end: buffered.end(i),
      });
    }

    setProgressBuffer(buffer);
  }, []);

  useEffect(() => {
    setProgressState(0);
    setProgressBuffer([]);
  }, [url]);

  useEffect(() => {
    if (typeof newProgress === 'number') {
      setProgress(newProgress);
    }
  }, [newProgress]);

  useEffect(() => {
    if (mediaRef.current && typeof newProgressPercentage === 'number') {
      setProgress(mediaRef.current.duration * newProgressPercentage);
    }
  }, [newProgressPercentage]);

  return {
    progress,
    progressMax,
    progressBuffer,
    setProgress,
    updateProgressMax,
    updateProgress,
    updateProgressBuffered,
  };
}
