import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import { MessageViewPageType } from 'MAIN/ChatView/Messages/Messages.types';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import StatusBar from '../components/StatusBar';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import Header from './Header';
import { useStickerBubblePresenter } from './StickerBubble.presenter';
import styles from './styles.m.css';

interface StickerBubbleProps {
  message: ChatMessage;
  children: React.ReactNode;
  messageViewPageType: MessageViewPageType;
  isView: boolean;
}

export const StickerBubble: FC<StickerBubbleProps> = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;
  const presenter = useStickerBubblePresenter(message);
  const { comment } = message.payload;

  const classes = classNames(styles.bubble, {
    [styles.bubbleFilled]: presenter.isFilledBubble,
    [styles.myBubble]: message.fromMe,
  });

  return (
    <MessageLayout
      message={message}
      className={classes}
      containerClassName={styles.container}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      {presenter.isExistHeader && (
        <Header
          message={message}
          isShowForward={presenter.isShowForward}
        />
      )}
      <div className={styles.content}>
        {children}
        {!comment && (
          <StatusBar
            className={styles.statusBar}
            message={message}
            viewsClassName={styles.views}
          />
        )}
      </div>
      {comment && comment.length && (
        <div className={styles.comment}>
          <TextRenderer>{comment}</TextRenderer>
          <StatusBar
            message={message}
            className={styles.commentedStatusBar}
            viewsClassName={styles.views}
          />
        </div>
      )}
      <ThreadButton message={message} />
    </MessageLayout>
  );
});
