import { useLocalStore } from 'mobx-react';

import { useTranslation } from 'APP/packages/translations';
import Entities from 'APP/store';

export const useVoicesItemPresenter = ({ groupId, messageId }) => {
  const { t } = useTranslation();
  const presenter = useLocalStore(() => ({
    get message() {
      const group = Entities.GroupsStore.getGroupById(groupId);

      if (!group) {
        return null;
      }

      return group.sharedData.getMessageById(messageId);
    },
    get group() {
      return Entities.GroupsStore.getGroupById(groupId);
    },
    get title() {
      if (
        presenter.group.isChannel &&
        !presenter.group.withMeInAdmins &&
        presenter.message.forward === null
      ) {
        return presenter.group.name;
      }

      if (presenter.message.forward === null) {
        const senderUser = presenter.message.senderUser;

        if (senderUser.id === Entities.UsersStore.Me.id) {
          return t('common:appeal_to_user');
        }

        return senderUser.avatarTitle;
      }

      const { avatarTitle } = presenter.message.forward;

      return t('forward_from', { 0: avatarTitle, interpolation: { escapeValue: false } });
    },
  }));

  return presenter;
};
