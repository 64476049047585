import { observer } from 'mobx-react';
import React, { FC, MouseEventHandler, ReactElement } from 'react';

import { PayloadType } from 'APP/model/message/messageModel.types';
import type { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';

import Advertisement from './Payloads/Advertisement';
import Album from './Payloads/Album';
import Article from './Payloads/Article';
import Audio from './Payloads/Audio';
import { ButtonsPayload } from './Payloads/ButtonsPayload/ButtonsPayload';
import Contact from './Payloads/Contact';
import { DeletedByMePayload } from './Payloads/DeletedByMePayload/DeletedByMePayload';
import File from './Payloads/File';
import Image from './Payloads/Image';
import Location from './Payloads/Location';
import { MiniatureMessageSticker } from './Payloads/MiniatureMessageSticker/MiniatureMessageSticker';
import Text from './Payloads/Text';
import Video from './Payloads/Video';
import Voice from './Payloads/Voice';

type TMiniatureMessageType =
  | PayloadType.AudioMessage
  | PayloadType.Contact
  | PayloadType.File
  | PayloadType.Image
  | PayloadType.Album
  | PayloadType.Location
  | PayloadType.Sticker
  | PayloadType.RichText
  | PayloadType.ButtonsSelected
  | PayloadType.Video
  | PayloadType.VoiceMessage
  | PayloadType.Article
  | PayloadType.Advertisement
  | PayloadType.Buttons
  | PayloadType.MessageWasDeletedByMe;

const payloads: Record<
  TMiniatureMessageType,
  FC<{ message: ChatMessage; title: string | ReactElement; onClick?: MouseEventHandler }>
> = {
  [PayloadType.AudioMessage]: Audio,
  [PayloadType.Contact]: Contact,
  [PayloadType.File]: File,
  [PayloadType.Image]: Image,
  [PayloadType.Album]: Album,
  [PayloadType.Location]: Location,
  [PayloadType.Sticker]: MiniatureMessageSticker,
  [PayloadType.RichText]: Text,
  [PayloadType.ButtonsSelected]: Text,
  [PayloadType.Video]: Video,
  [PayloadType.VoiceMessage]: Voice,
  [PayloadType.Article]: Article,
  [PayloadType.Advertisement]: Advertisement,
  [PayloadType.Buttons]: ButtonsPayload,
  [PayloadType.MessageWasDeletedByMe]: DeletedByMePayload,
};

interface IMiniatureMessageProps {
  message: ChatMessage | null;
  title: string | ReactElement;
  onClick?: MouseEventHandler;
}

export const MiniatureMessage: FC<IMiniatureMessageProps> = observer((props) => {
  const { message, title, onClick } = props;

  if (!message) {
    return null;
  }

  const Component = payloads[message.payload.payloadType as TMiniatureMessageType];

  if (!Component) {
    return null;
  }

  return (
    <Component
      message={message}
      title={title}
      onClick={onClick}
    />
  );
});
