import { observer } from 'mobx-react';
import React from 'react';

import { Layout } from '../components/Layout';
import usePresenter from './presenter';

const Article = ({ message, title, onClick }) => {
  const presenter = usePresenter({ message });

  if (!message) {
    return null;
  }

  return (
    <Layout
      imageUrl={presenter.imageUrl}
      title={title}
      text={presenter.title}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default observer(Article);
