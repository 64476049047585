import React from 'react';

import IcMusic16 from 'ICONS/ic-music-16.svg';

import { Layout } from '../components/Layout';

const Audio = ({ message, title, onClick }) => {
  return (
    <Layout
      Icon={IcMusic16}
      title={title}
      text={message.payload.typeDescription}
      messageId={message.id}
      onClick={onClick}
    />
  );
};

export default Audio;
