import { action, computed, observable } from 'mobx';

import Tasks from 'APP/Tasks';
import { IDeleteMessagesMode, PayloadType } from 'APP/model/message/messageModel.types';
import dateService from 'APP/packages/date';
import { MessageError } from 'STORE/Messages/Message/ChatMessage/ChatMessages.types';
import { Message } from 'STORE/Messages/Message/Message';
import {
  isMessageCanBeAddedToCollection,
  isMessageCanBeEdit,
  isMessageCanBeForward,
  isMessageCanBeQuoted,
} from 'STORE/Messages/Message/helper';

export class ChatMessage extends Message {
  @observable error = ''; // 'edit', 'delete', 'send' or ''
  @observable uploadProgress; // for uploading media files
  @observable isLocalFirst = true;
  @observable isLocalLast = true;
  @observable hasNewMessagePlaceholder = false;
  @observable isEditQueue = false;
  isProgress = false;

  constructor(data, messages) {
    super(data);
    this.messages = messages;
  }

  setIsProgress(isProgress) {
    this.isProgress = isProgress;
  }

  isNextDay(prevMessage) {
    if (!prevMessage) {
      return true;
    }

    return !dateService.isSameDay(prevMessage?.displayTime, this.displayTime);
  }

  @computed
  get canBeCopied() {
    return (
      (this.payload.payloadType === PayloadType.RichText ||
        this.payload.payloadType === PayloadType.Buttons ||
        this.payload.payloadType === PayloadType.ButtonsSelected ||
        this.payload.payloadType === PayloadType.Contact ||
        this.payload.comment) &&
      !this.isArticleDraft &&
      !this.isFakeGroup &&
      !this.payload.isSystem
    );
  }

  @computed
  get canBeTranslated() {
    if (this.payload.payloadType === PayloadType.Advertisement) {
      return false;
    }

    if (
      this.payload.payloadType !== PayloadType.RichText &&
      this.payload.payloadType !== PayloadType.Article &&
      !this.payload.comment
    ) {
      return false;
    }

    return (
      !this.payload.isSystem &&
      !this.fromMe &&
      !this.hasTranslation &&
      !this.isArticleDraft &&
      !this.isFakeGroup
    );
  }

  @computed
  get canBeCreateLink() {
    const { group } = this.messages;
    return (
      group.isChannel &&
      group.isOpen &&
      !this.payload.isSystem &&
      this.payload.payloadType !== PayloadType.Advertisement &&
      !this.isArticleDraft &&
      !this.isFakeGroup &&
      !this.isFake
    );
  }

  @computed
  get hasError() {
    return Boolean(this.error);
  }

  @computed
  get canBeResend() {
    return this.hasError && !this.isArticleDraft && !this.isFakeGroup;
  }

  @computed
  get isSelected() {
    return this.messages.multiSelect.selectedMessagesIds.has(this.id);
  }

  @action
  setIsEditQueue(isEditQueue) {
    this.isEditQueue = isEditQueue;
  }

  @action
  setError(reason) {
    this.error = reason;
  }

  @action
  resetError() {
    this.error = null;
  }

  @computed
  get canBeQuoted() {
    if (this.payload.isSystem || this.isArticleDraft || this.isFakeGroup) {
      return false;
    }

    const { group } = this.messages;

    return isMessageCanBeQuoted(this) && !group.isBlockedByMe && !group.isBlockedMe;
  }

  @computed
  get canAddToSpaceCollection() {
    return isMessageCanBeAddedToCollection(this);
  }

  @computed
  get canBeEdit() {
    if (
      this.payload.isSystem ||
      this.isArticleDraft ||
      this.payload.payloadType === PayloadType.Advertisement
    ) {
      return false;
    }

    const { group } = this.messages;

    return (
      isMessageCanBeEdit(this) &&
      !group.isBlockedByMe &&
      !group.isBlockedMe &&
      !group.isBot &&
      !this.forward
    );
  }

  @computed
  get canBeForward() {
    return (
      !(this.messages.group?.withoutMe && this.messages.group?.isClosed) &&
      !this.payload.isSystem &&
      isMessageCanBeForward(this) &&
      !this.isArticleDraft &&
      !this.isFakeGroup &&
      !this.isDeletedByMe
    );
  }

  @computed
  get canBePinned() {
    if (
      this.isFakeGroup ||
      this.isArticleDraft ||
      this.payload.payloadType === PayloadType.Advertisement ||
      this.isDeletedByMe
    ) {
      return false;
    }

    return (
      this.messages.group.canPinMessage &&
      !this.payload.isSystem &&
      !this.isFake &&
      (this.messages.group.isP2P ||
        this.messages.group.isChatGroup ||
        this.messages.group.isSavedMessages ||
        this.messages.group.isChannel)
    );
  }

  get canUnpin() {
    return this.messages.group.canPinMessage;
  }

  @computed
  get canBeSelected() {
    if (!this.canBeDelete && !this.canBeForward) {
      return false;
    }
    // Todo уже можно не вводить эти ограничения !this.isFake && !this.hasError.
    //  Ибо с фейковыми уже все норм (можно удалять) и в проверке ниже есть косяк.
    //  Есил сообщение получит ошибку редактирования его не получится выделить.
    return !this.isFake && !this.hasError && this.payload.payloadType !== PayloadType.Advertisement;
  }

  @computed
  get canBeReported() {
    return (
      !this.isFake &&
      !this.isFakeGroup &&
      !this.payload.isSystem &&
      !this.hasError &&
      !this.fromMe &&
      !this.isDeletedByMe
    );
  }

  @computed
  get showSelect() {
    return this.messages.multiSelect.hasSelectedMessages && this.canBeSelected;
  }

  @computed
  get renderPayload() {
    if (this.isShowTranslate && this.translatedPayload) {
      return this.translatedPayload;
    }
    if (this.editedPayload) {
      return this.editedPayload;
    }
    return this.payload;
  }

  @computed
  get isAvailableReactions() {
    if (this.isArticleDraft || this.isFakeGroup || this.isDeletedByMe) {
      return false;
    }

    return (
      !this.payload.isSystem &&
      !this.isFake &&
      !this.messages.group.isBlockedMe &&
      this.usersReactions
    );
  }

  @computed
  get hasMenuItems() {
    return (
      this.canBeResend ||
      this.canBeCopied ||
      this.canBeQuoted ||
      this.canBeEdit ||
      this.canBeForward ||
      this.canBePinned ||
      this.canBeTranslated ||
      this.canBeSelected ||
      this.canBeDelete ||
      this.canBeLeaveComment ||
      this.canBeSendNow ||
      this.canBeSchedule
    );
  }

  @computed
  get canBeDelete() {
    if (!this.payload.canBeDelete) {
      return false;
    }

    if (this.messages.group.isChatGroup && this.messages.group.withoutMe) {
      return false;
    }

    if (!this.messages.group.isP2P && this.messages.group.withMeInAdmins) {
      return true;
    }

    return !this.messages.group.isChannel && (this.fromMe || this.isSavedMessagesLeft);
  }

  @computed
  get canBeDeleteForMe() {
    return (
      !this.isDeletedByMe &&
      !this.messages.group.isChannel &&
      !this.messages.group.isSavedMessages &&
      !this.isFake &&
      !this.payload.isSystem &&
      !this.isScheduled
    );
  }

  @computed
  get canBeLeaveComment() {
    if (
      this.messages.group.isP2P ||
      this.messages.group.isBot ||
      this.messages.group.isSavedMessages ||
      this.messages.group.isThread ||
      this.messages.group.isBlockedMe ||
      this.messages.isFakeGroup ||
      this.isFake ||
      this.payload.payloadType === PayloadType.Advertisement ||
      (this.payload.isSystem && this.payload.payloadType !== PayloadType.SystemStream)
    ) {
      return false;
    }

    return this.messages.group.canLeaveComment;
  }

  @computed
  get canBeReactionsOrViews() {
    if (
      this.isFake ||
      this.isArticleDraft ||
      this.isFakeGroup ||
      this.group.isP2P ||
      this.group.isBot ||
      this.group.isSavedMessages ||
      this.group.isBlockedMe ||
      (this.payload.isSystem && this.payload.payloadType !== PayloadType.SystemStream) ||
      this.isDeletedByMe
    ) {
      return false;
    }

    if (this.group.isChatGroup && this.group.withoutMe) {
      return false;
    }

    if (this.group.isChannel && !this.group.withMeInAdmins) {
      return false;
    }

    if (
      this.group.isThread &&
      this.group.parentGroup?.isChannel &&
      !this.group.parentGroup?.withMeInAdmins
    ) {
      return false;
    }

    const { message } = this.payload;
    if (!message.usersReactions) {
      return false;
    }

    return message.views >= 2 || Object.keys(message.usersReactions.usersReactions).length > 0;
  }

  @computed
  get canBePublishNow() {
    return this.isArticleDraft;
  }

  @computed
  get isPinned() {
    return (
      this.root.GroupsStore.activeGroup &&
      this.root.GroupsStore.activeGroup.pinnedMessages.messages
        .map((pinnedMessage) => pinnedMessage.id)
        .includes(this.id)
    );
  }

  delete = async (mode = IDeleteMessagesMode.All) => {
    const data = { groupId: this.group.id, messages: [this] };

    if (this.isScheduled) {
      Tasks.messaging.deleteScheduledMessages(data);
    } else {
      Tasks.messaging.deleteMessages({
        ...data,
        mode,
      });
    }

    return true;
  };

  resend = () => {
    //TODO: refactoring the whole file or at least add errors to the constant
    if (this.error === MessageError.Send) {
      return Tasks.messaging.resendChatMessage(this);
    }
    if (this.error === MessageError.Edit) {
      return Tasks.messaging.editMessage(this);
    }
    if (this.error === MessageError.Delete) {
      this.delete();
    }
    if (this.error === MessageError.DeleteByMe) {
      this.delete(IDeleteMessagesMode.Me);
    }
    if (this.error === MessageError.Forward) {
      return Tasks.messaging.reForwardMessage(this);
    }
  };

  reschedule = () => {
    if (this.error === MessageError.Schedule) {
      return Tasks.messaging.rescheduleMessage(this);
    } else if (this.error === MessageError.Send) {
      return Tasks.messaging.sendNowScheduledMessages({
        groupId: this.groupId,
        messages: [this],
      });
    } else if (this.error === MessageError.Edit) {
      return Tasks.messaging.editScheduledMessage({
        message: this,
        date: new Date(parseInt(this.clientTime)),
      });
    } else if (this.error === MessageError.Delete) {
      return this.delete();
    }
  };

  @computed
  get canBeDownloaded() {
    if (this.isScheduled) {
      return false;
    }

    return (
      this.payload.payloadType === PayloadType.VoiceMessage ||
      this.payload.payloadType === PayloadType.AudioMessage ||
      this.payload.payloadType === PayloadType.Video ||
      this.payload.payloadType === PayloadType.Image ||
      this.payload.payloadType === PayloadType.File
    );
  }

  @computed
  get canBeSendNow() {
    return (
      this.isScheduled &&
      !this.payload.isSystem &&
      !this.isFake &&
      !this.hasError &&
      this.payload.payloadType !== PayloadType.Advertisement
    );
  }

  @computed
  get canBeSchedule() {
    return (
      this.isScheduled &&
      !this.payload.isSystem &&
      !this.isFake &&
      !this.hasError &&
      this.payload.payloadType !== PayloadType.Advertisement
    );
  }

  @computed
  get canBeReschedule() {
    return this.hasError && this.isScheduled;
  }

  @action
  focusMessage() {
    this.messages.focusMessage(this.id);
  }

  @action
  unfocusMessage() {
    this.messages.unfocusMessage();
  }

  @computed
  get isFocused() {
    return this.messages.focusedMessageId === this.id;
  }

  @computed
  get isDeletedByMe() {
    return this.renderPayload.payloadType === PayloadType.MessageWasDeletedByMe;
  }

  @computed
  get editedPayload() {
    return this.messages.editedPayloads.get(`${this.id}`) || null;
  }

  @computed
  get translatedPayload() {
    return this.messages.translations.get(`${this.id}${this.editTime || ''}`)?.payload || null;
  }
}
