import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import { Layout } from '../components/Layout';
import usePresenter from './presenter';
import styles from './styles.m.css';

const Album = ({ message, title, onClick }) => {
  const { typeDescription, comment } = message.payload;
  const presenter = usePresenter(message);

  return (
    <Layout
      imageUrl={presenter.imageUrl}
      graphicSize={presenter.graphicSize}
      title={title}
      text={typeDescription}
      messageId={message.id}
      onClick={onClick}
    >
      {comment && (
        <TextRenderer
          isPlainText
          className={styles.text}
        >
          {comment}
        </TextRenderer>
      )}
    </Layout>
  );
};

export default Album;
