import { PayloadType } from 'APP/model/message/messageModel.types';
import { AudioPayload } from 'MAIN/ChatView/Messages/Message/Payload/AudioPayload/AudioPayload';
import { DeletedByMePayload } from 'MAIN/ChatView/Messages/Message/Payload/DeletedByMe/DeletedByMePayload';
import { FilePayload } from 'MAIN/ChatView/Messages/Message/Payload/FilePayload/FilePayload';

import { ArticleBubble } from '../bubbles/ArticleBubble/ArticleBubble';
import { ButtonsBubble } from '../bubbles/ButtonsBubble/ButtonsBubble';
import { ContactBubble } from '../bubbles/ContactBubble/ContactBubble';
import { DeletedByMeBubble } from '../bubbles/DeletedByMeBubble/DeletedByMeBubble';
import { FileBubble } from '../bubbles/FileBubble/FileBubble';
import { MediaBubble } from '../bubbles/MediaBubble/MediaBubble';
import { StickerBubble } from '../bubbles/StickerBubble/StickerBubble';
import { StreamBubble } from '../bubbles/StreamBubble/StreamBubble';
import { TextBubble } from '../bubbles/TextBubble/TextBubble';
import { UnsupportedBubble } from '../bubbles/UnsupportedBubble/UnsupportedBubble';
import Advertisement from './Advertisement';
import Album from './Album';
import Article from './Article';
import Call from './Call';
import { Contact } from './Contact';
import { ImagePayload } from './ImagePayload/ImagePayload';
import Location from './Location';
import Sticker from './Sticker';
import Stream from './Stream';
import { System } from './System/System';
import Text from './Text';
import { UnsupportedMessagePayload } from './UnsupportedMessagePayload/UnsupportedMessagePayload';
import { VideoPayload } from './VideoPayload/VideoPayload';
import { VoicePayload } from './VoicePayload/VoicePayload';
import Buttons from './bots/Buttons';
import ButtonsSelected from './bots/ButtonsSelected';

const payloadComponents = {
  [PayloadType.AudioMessage]: AudioPayload,
  [PayloadType.Contact]: Contact,
  [PayloadType.File]: FilePayload,
  [PayloadType.Image]: ImagePayload,
  [PayloadType.Album]: Album,
  [PayloadType.Location]: Location,
  [PayloadType.Sticker]: Sticker,
  [PayloadType.RichText]: Text,
  [PayloadType.Video]: VideoPayload,
  [PayloadType.VoiceMessage]: VoicePayload,
  [PayloadType.Article]: Article,
  [PayloadType.Advertisement]: Advertisement,

  // message deleted only for me
  [PayloadType.MessageWasDeletedByMe]: DeletedByMePayload,

  // system messages
  [PayloadType.SystemChatNewlyCreatedWelcome]: System,
  [PayloadType.SystemThreadNewlyCreatedWelcome]: System,
  [PayloadType.SystemChatNewlyCreatedPhoneBookUserJoined]: System,
  [PayloadType.SystemChatJoined]: System,
  [PayloadType.SystemChatAdded]: System,
  [PayloadType.SystemChatAvatarChanged]: System,
  [PayloadType.SystemChatMessagePinned]: System,
  [PayloadType.SystemChatOwnerChanged]: System,
  [PayloadType.SystemChatAdminsAdded]: System,
  [PayloadType.SystemChatAdminsRemoved]: System,
  [PayloadType.SystemChatDescriptionChanged]: System,
  [PayloadType.SystemChatRenamed]: System,
  [PayloadType.SystemChatUsersRemoved]: System,
  [PayloadType.SystemChatUsersBanned]: System,
  [PayloadType.SystemChatLeft]: System,
  [PayloadType.SystemChatTypeChanged]: System,
  [PayloadType.SystemChatCategoryChanged]: System,
  [PayloadType.SystemChatMessagesSettingsChanged]: System,
  [PayloadType.SystemChatAddedToSpace]: System,
  [PayloadType.SystemChatRemovedFromSpace]: System,

  // call system messages
  [PayloadType.Call]: Call,
  [PayloadType.SystemCall]: Call,
  [PayloadType.SystemCallFinished]: Call,
  [PayloadType.SystemCallStarted]: Call,

  //stream
  [PayloadType.SystemStream]: Stream,

  // bots
  [PayloadType.Buttons]: Buttons,
  [PayloadType.ButtonsSelected]: ButtonsSelected,
};

export const usePayloadPresenter = ({ payloadType }) => {
  const presenter = {
    get bubbleComponent() {
      switch (payloadType) {
        case PayloadType.Album:
        case PayloadType.Image:
        case PayloadType.Location:
        case PayloadType.Video:
          return MediaBubble;
        case PayloadType.SystemStream:
          return StreamBubble;
        case PayloadType.AudioMessage:
        case PayloadType.File:
        case PayloadType.VoiceMessage:
          return FileBubble;
        case PayloadType.RichText:
        case PayloadType.ButtonsSelected:
          return TextBubble;
        case PayloadType.MessageWasDeletedByMe:
          return DeletedByMeBubble;
        case PayloadType.Contact:
          return ContactBubble;
        case PayloadType.Sticker:
          return StickerBubble;
        case PayloadType.Buttons:
          return ButtonsBubble;
        case PayloadType.Article:
        case PayloadType.Advertisement:
          return ArticleBubble;
        case PayloadType.SystemCall:
        case PayloadType.SystemCallStarted:
        case PayloadType.SystemCallFinished:
        case PayloadType.SystemChatNewlyCreatedWelcome:
        case PayloadType.SystemThreadNewlyCreatedWelcome:
        case PayloadType.SystemChatNewlyCreatedPhoneBookUserJoined:
        case PayloadType.SystemChatJoined:
        case PayloadType.SystemChatAdded:
        case PayloadType.SystemChatAvatarChanged:
        case PayloadType.SystemChatMessagePinned:
        case PayloadType.SystemChatOwnerChanged:
        case PayloadType.SystemChatAdminsAdded:
        case PayloadType.SystemChatAdminsRemoved:
        case PayloadType.SystemChatDescriptionChanged:
        case PayloadType.SystemChatRenamed:
        case PayloadType.SystemChatUsersRemoved:
        case PayloadType.SystemChatUsersBanned:
        case PayloadType.SystemChatLeft:
        case PayloadType.SystemChatTypeChanged:
        case PayloadType.SystemChatCategoryChanged:
        case PayloadType.SystemChatMessagesSettingsChanged:
        case PayloadType.SystemChatAddedToSpace:
        case PayloadType.SystemChatRemovedFromSpace:
          return null;
        default:
          return UnsupportedBubble;
      }
    },

    get payloadComponent() {
      return payloadComponents[payloadType] || UnsupportedMessagePayload;
    },
  };

  return presenter;
};
