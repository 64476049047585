import { observer } from 'mobx-react';
import React from 'react';

import { PayloadType } from 'APP/model/message/messageModel.types';

import { ArticlePayload } from './ArticlePayload/ArticlePayload';
import { CallPayload } from './CallPayload/CallPayload';
import { DefaultPayload } from './DefaultPayload/DefaultPayload';
import { DeletedByMePayload } from './DeletedByMePayload/DeletedByMePayload';
import { StreamPayload } from './StreamPayload/StreamPayload';
import { SystemPayload } from './SystemPayload/SystemPayload';
import { UnsupportedPayload } from './UnsupportedPayload/UnsupportedPayload';

const payloadComponents = {
  [PayloadType.Call]: CallPayload,
  [PayloadType.SystemCall]: CallPayload,
  [PayloadType.SystemCallFinished]: CallPayload,
  [PayloadType.SystemCallStarted]: CallPayload,
  [PayloadType.Article]: ArticlePayload,
  [PayloadType.SystemStream]: StreamPayload,

  [PayloadType.RichText]: DefaultPayload,
  [PayloadType.File]: DefaultPayload,
  [PayloadType.AudioMessage]: DefaultPayload,
  [PayloadType.VoiceMessage]: DefaultPayload,
  [PayloadType.Image]: DefaultPayload,
  [PayloadType.Album]: DefaultPayload,
  [PayloadType.Video]: DefaultPayload,
  [PayloadType.Sticker]: DefaultPayload,
  [PayloadType.Location]: DefaultPayload,
  [PayloadType.Contact]: DefaultPayload,
  [PayloadType.Buttons]: DefaultPayload,
  [PayloadType.ButtonsSelected]: DefaultPayload,
  [PayloadType.Advertisement]: DefaultPayload,

  // message deleted only for me
  [PayloadType.MessageWasDeletedByMe]: DeletedByMePayload,

  // system messages
  [PayloadType.SystemChatNewlyCreatedWelcome]: SystemPayload,
  [PayloadType.SystemThreadNewlyCreatedWelcome]: SystemPayload,
  [PayloadType.SystemChatNewlyCreatedPhoneBookUserJoined]: SystemPayload,
  [PayloadType.SystemChatJoined]: SystemPayload,
  [PayloadType.SystemChatAdded]: SystemPayload,
  [PayloadType.SystemChatAvatarChanged]: SystemPayload,
  [PayloadType.SystemChatMessagePinned]: SystemPayload,
  [PayloadType.SystemChatOwnerChanged]: SystemPayload,
  [PayloadType.SystemChatAdminsAdded]: SystemPayload,
  [PayloadType.SystemChatAdminsRemoved]: SystemPayload,
  [PayloadType.SystemChatDescriptionChanged]: SystemPayload,
  [PayloadType.SystemChatRenamed]: SystemPayload,
  [PayloadType.SystemChatUsersRemoved]: SystemPayload,
  [PayloadType.SystemChatUsersBanned]: SystemPayload,
  [PayloadType.SystemChatLeft]: SystemPayload,
  [PayloadType.SystemChatTypeChanged]: SystemPayload,
  [PayloadType.SystemChatCategoryChanged]: SystemPayload,
  [PayloadType.SystemChatMessagesSettingsChanged]: SystemPayload,
  [PayloadType.SystemChatAddedToSpace]: SystemPayload,
  [PayloadType.SystemChatRemovedFromSpace]: SystemPayload,
};

export const LastMessageDescription = observer(({ name, group, payload }) => {
  const PayloadComponent = payloadComponents[payload.payloadType];

  if (!PayloadComponent) {
    return <UnsupportedPayload name={name} />;
  }

  return (
    <PayloadComponent
      name={name}
      group={group}
      payload={payload}
    />
  );
});
