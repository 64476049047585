import { action, computed, observable } from 'mobx';

import { VideoPlayerType } from 'UIKIT/Media/VideoPlayer/VideoPlayer.constants';
import { IVideoPlayerConfig } from 'UIKIT/Media/VideoPlayer/VideoPlayer.types';

export class GlobalVideoPlayer {
  @observable url: string | null = null;
  @observable previewUrl: string | null = null;
  @observable container: HTMLElement | null = null;
  @observable type: VideoPlayerType | null = null;
  @observable config: Partial<IVideoPlayerConfig> = {};
  @observable onShowMiniHandler: (() => void) | null = null;
  @observable onExpandMiniHandler: (() => void) | null = null;

  @action
  setUrl(url: string | null): void {
    this.url = url;
  }

  @action
  setPreviewUrl(previewUrl: string | null): void {
    this.previewUrl = previewUrl;
  }

  @action
  setContainer(container: HTMLElement | null): void {
    this.container = container;
  }

  @action
  setType(type: VideoPlayerType | null): void {
    this.type = type;
  }

  @action
  reset(): void {
    this.setType(null);
    this.setUrl(null);
    this.setPreviewUrl(null);
    this.setContainer(null);
    this.setShowMiniHandler(null);
    this.setExpandMiniHandler(null);
  }

  @action
  setShowMiniHandler(onShowMiniHandler: (() => void) | null): void {
    this.onShowMiniHandler = onShowMiniHandler;
  }

  @action
  setExpandMiniHandler(onExpandMiniHandler: (() => void) | null): void {
    this.onExpandMiniHandler = onExpandMiniHandler;
  }

  @action
  setConfig(newConfig: Partial<IVideoPlayerConfig>): void {
    this.config = {
      ...this.config,
      ...newConfig,
    };
  }

  @computed
  get isMiniShown(): boolean {
    return !!this.url && this.type === VideoPlayerType.Mini;
  }
}
