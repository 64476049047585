import { useCallback, useEffect, useRef, useState } from 'react';

const isVideoPlaying = (video) => !(video.paused || video.ended);

export function useVideoPlayer({ url, autoPlay, newIsPlayingState, onTogglePlay }) {
  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const didMountRef = useRef(false);
  const playPromise = useRef(Promise.resolve());

  const [isPlaying, setIsPlayingState] = useState(!!autoPlay);

  const togglePlay = useCallback(() => {
    isVideoPlaying(videoRef.current) ? pause() : play();
  }, [url]);

  const setIsPlaying = useCallback(
    (state) => {
      setIsPlayingState(state);
      onTogglePlay(state);
    },
    [onTogglePlay]
  );

  const play = useCallback(() => {
    playPromise.current = videoRef.current.play();
  }, [url]);

  const pause = useCallback(() => {
    playPromise.current.then(() => videoRef.current.pause());
  }, [url]);

  useEffect(() => {
    if (didMountRef.current) {
      videoRef.current.load();
    } else {
      didMountRef.current = true;
    }
  }, [url]);

  useEffect(() => {
    if (newIsPlayingState && !isPlaying) {
      play();
    }

    if (!newIsPlayingState && isPlaying) {
      pause();
    }
  }, [newIsPlayingState]);

  useEffect(() => {
    const onPlay = () => setIsPlaying(true);
    const onPause = () => setIsPlaying(false);

    if (videoRef.current) {
      videoRef.current.addEventListener('play', onPlay);
      videoRef.current.addEventListener('pause', onPause);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('play', onPlay);
        videoRef.current.removeEventListener('pause', onPause);
        videoRef.current.load();
      }
    };
  }, [videoRef.current]);

  return {
    videoRef,
    videoContainerRef,
    isPlaying,
    togglePlay,
    play,
    pause,
  };
}
