import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { FC } from 'react';

import { IconView, IconViewSizes } from 'APP/packages/UiKit/IconView/IconView';
import icMessage from 'ICONS/ic-message-16.svg';
import icNext from 'ICONS/ic-next.svg';
import IconMention from 'ICONS/mention.svg';
import { ChatMessage } from 'STORE/Messages/Message/ChatMessage/ChatMessage';
import { Badge } from 'UIKIT/Badge/Badge';

import { useThreadButtonPresenter } from './ThreadButton.presenter';
import styles from './ThreadButton.styles.m.css';

interface IThreadButtonProps {
  className?: string;
  message: ChatMessage;
}

export const ThreadButton: FC<IThreadButtonProps> = observer((props) => {
  const { message, className = undefined } = props;
  const presenter = useThreadButtonPresenter(message);

  if (!presenter.isVisible) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, className)}
      role="button"
      tabIndex={0}
      onClick={presenter.onClick}
    >
      <IconView
        Icon={icMessage}
        className={styles.icon}
        size={IconViewSizes.Small}
      />
      <span className={styles.text}>{presenter.caption}</span>
      {presenter.hasUnreadMentions && <IconMention className={styles.mention} />}
      {presenter.hasUnread && <Badge quantity={presenter.countUnread} />}
      <IconView
        Icon={icNext}
        className={styles.iconNext}
        size={IconViewSizes.Medium}
      />
    </div>
  );
});
