import { IPersonalizedGroup } from 'APP/model/group/groupModel.types';
import { IUserDisplayData } from 'APP/model/user/userModel.types';
import { ClearGroupMessageRequest } from 'APP/packages/api/types/model/clearGroupMessageRequest';
import { ClearGroupMessageResponse } from 'APP/packages/api/types/model/clearGroupMessageResponse';
import {
  DeleteMessagesRequest,
  DeleteMessagesRequestModeEnum,
} from 'APP/packages/api/types/model/deleteMessagesRequest';
import { DeleteScheduledMessagesRequest } from 'APP/packages/api/types/model/deleteScheduledMessagesRequest';
import { ForwardMessagesResponse } from 'APP/packages/api/types/model/forwardMessagesResponse';
import { GroupParticipantChangeRequest } from 'APP/packages/api/types/model/groupParticipantChangeRequest';
import { ScheduledMessageResponse } from 'APP/packages/api/types/model/scheduledMessageResponse';
import { SendMessageResponse } from 'APP/packages/api/types/model/sendMessageResponse';
import { SendNewMessageRequest } from 'APP/packages/api/types/model/sendNewMessageRequest';
import { UpdateMessageRequest } from 'APP/packages/api/types/model/updateMessageRequest';

export enum PayloadType {
  RichText = 'RichText',
  File = 'File',
  AudioMessage = 'AudioMessage',
  Image = 'Image',
  LoadImageByLink = 'LoadImageByLink', // only client payload for copy paste image to article from google doc
  Album = 'Album',
  Video = 'Video',
  Sticker = 'Sticker',
  Location = 'Location',
  VoiceMessage = 'VoiceMessage',
  Contact = 'Contact',
  Call = 'Call',
  Article = 'Article',
  Advertisement = 'Advertisement',

  // deleted by me
  MessageWasDeletedByMe = 'message.was.deleted.by.me',

  // bots
  Buttons = 'Buttons',
  ButtonsSelected = 'buttons.selected',

  // system messages
  SystemStream = 'system.stream',
  SystemCall = 'system.call',
  SystemCallStarted = 'system.call.started',
  SystemCallFinished = 'system.call.finished',
  SystemChatNewlyCreatedWelcome = 'system.chat.newly.created.welcome',
  SystemThreadNewlyCreatedWelcome = 'system.thread.newly.created.welcome',
  SystemChatNewlyCreatedPhoneBookUserJoined = 'system.chat.newly.created.phonebook_user_joined_gem4me',
  SystemChatJoined = 'system.chat.joined',
  SystemChatAdded = 'system.chat.added',
  SystemChatAvatarChanged = 'system.chat.avatar.changed',
  SystemChatMessagePinned = 'system.chat.message.pinned',
  SystemChatOwnerChanged = 'system.chat.owner.changed',
  SystemChatAdminsAdded = 'system.chat.admins.added',
  SystemChatAdminsRemoved = 'system.chat.admins.removed',
  SystemChatDescriptionChanged = 'system.chat.description.changed',
  SystemChatRenamed = 'system.chat.renamed',
  SystemChatUsersRemoved = 'system.chat.users.removed',
  SystemChatUsersBanned = 'system.chat.users.banned',
  SystemChatLeft = 'system.chat.left',
  SystemChatTypeChanged = 'system.chat.type.changed',
  SystemChatCategoryChanged = 'system.chat.category.changed',
  SystemChatMessagesSettingsChanged = 'system.chat.messages.settings.changed',
  SystemChatAddedToSpace = 'system.chat.added.to.space',
  SystemChatRemovedFromSpace = 'system.chat.removed.from.space',
}

export enum SendMessageStatus {
  Failed = 'failed',
  Seen = 'seen',
  Sending = 'sending',
  Sent = 'sent',
}

export enum MessageKeyWordType {
  Congratulation = 'CONGRATULATION',
  NewYear = 'NEW_YEAR',
}

export interface IForwardMessage {
  from: string;
  groupId?: string;
  messageId: string;
  senderId: string;
  clientGroupSourceId?: string;
}

export interface IUpdateMessageReaction {
  groupId: string;
  messageId: string;
  reactionId?: string;
}

export interface IMessagePayloadData {
  payloadType: string;
}

export interface IQuotedMessageData {
  id: string;
  payload: IMessagePayloadData;
  sender: string;
  senderId: string;
}

export interface IUserReactions {
  currentUserReactionId?: string;
  usersReactions: { [key: string]: number };
}

export interface IMessageKeyWord {
  keyWord: string;
  keyWordType: MessageKeyWordType;
}

//ToDo change serverTime, editTime and clientTime to number/ and use mapMessageToImessage
export interface IMessage {
  clientTime: string;
  clientUuid: string;
  deepLink?: string;
  editTime?: string;
  forwardedMessage?: IForwardMessage;
  groupId: string;
  id: string;
  links: string[];
  payload: IMessagePayloadData;
  quotedMessage?: IQuotedMessageData;
  usersReactions: IUserReactions;
  senderId: string;
  serverTime: string;
  threadId?: string;
  views: string;
}

export interface IMessageWithCommentCount {
  commentCount: number;
  message: IMessage;
}

export interface IReachTextMessagePayloadData {
  text: string;
  linkPreview?: string;
  linkPreviewCancelled?: boolean;
}

export interface IFileMessagePayloadData {
  comment?: string;
  url: string;
}

export interface IOrderedPayloadItem {
  id: string;
  payloadType: PayloadType;
  order: number;
  payload: IMessagePayloadData;
}

export interface IAlbumPayloadData {
  payloadType: PayloadType;
  comment?: string;
  payloads: IOrderedPayloadItem[];
}

export interface IArticlePayloadData {
  payloadType: PayloadType;
  title: string;
  payloads: IOrderedPayloadItem[];
}

type TMessagePayloadDataType = IReachTextMessagePayloadData | IFileMessagePayloadData;

export interface IMessageData<T extends TMessagePayloadDataType> {
  type: PayloadType;
  groupId: string;
  data: T;
  quotedMessage?: string;
}

export interface IConfirmReadGroupMessages {
  groupId: string;
  messageId: string;
}

export interface IForwardRequest {
  sourceGroupId: string;
  messagesToForward: {
    sourceMessageId: string;
    clientTs: number;
    clientUuid: string;
    targetGroupId?: string;
    targetContactId?: string;
    payloadIds?: string[];
  }[];
}

export type IForwardMessagesResponse = ForwardMessagesResponse;

export interface IDeleteMessagesRequest extends Omit<DeleteMessagesRequest, 'mode'> {
  mode: IDeleteMessagesMode;
}

export type IDeleteMessagesMode = DeleteMessagesRequestModeEnum;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const IDeleteMessagesMode = DeleteMessagesRequestModeEnum;

export type IDeleteScheduledMessagesRequest = DeleteScheduledMessagesRequest;

export interface IMessagesResponse {
  messages: IMessage[];
  groups: IPersonalizedGroup[];
  users: IUserDisplayData[];
}

export type IClearGroupMessageRequest = ClearGroupMessageRequest;
export type IClearGroupMessageResponse = ClearGroupMessageResponse;

export type IUpdateMessageRequest = UpdateMessageRequest;

export type IGroupParticipantChangeRequest = GroupParticipantChangeRequest;

export interface IScheduledMessage
  extends Omit<ScheduledMessageResponse, 'quotedMessage' | 'payload'> {
  quotedMessage?: IQuotedMessageData;
  payload: IMessagePayloadData;
}

export interface ISendNewMessageRequest extends Omit<SendNewMessageRequest, 'clientTime'> {
  clientTime: number;
}

export type ISendMessageResponse = SendMessageResponse;
