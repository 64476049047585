import React, { FC } from 'react';

import { AudioState } from 'MAIN/components/AudioState/AudioState';
import { AudioUploadState } from 'MAIN/components/AudioUploadState/AudioUploadState';
import { FileName } from 'UIKIT/FileName/FileName';
import { AudioPlayerTrackCaption } from 'UIKIT/Media/AudioPlayer/AudioPlayerTrackCaption/AudioPlayerTrackCaption';
import { PlayIconType } from 'UIKIT/PlayIcon/PlayIcon';

import styles from './MessageAudioPlayer.styles.m.css';

interface IMessageAudioPlayerProps {
  messageId: string;
  groupId: string;
  payloadId: string;
  isPlaying: boolean;
  isActiveTrack: boolean;
  progress: number;
  duration: number;
  fileName: string;
  size: number;
  uploadProgress: number | null;
  hasError: boolean;
  onTogglePlay(): void;
  cancelUploadAndDeleteMessage(): Promise<void>;
}

export const MessageAudioPlayer: FC<IMessageAudioPlayerProps> = (props) => {
  const {
    messageId,
    groupId,
    payloadId,
    isPlaying,
    isActiveTrack,
    fileName,
    size,
    duration,
    progress,
    uploadProgress = null,
    hasError,
    onTogglePlay,
    cancelUploadAndDeleteMessage,
  } = props;

  return (
    <div className={styles.container}>
      {uploadProgress !== null ? (
        <AudioUploadState
          className={styles.icon}
          uploadFileData={{
            uploadProgress,
            cancelUpload: cancelUploadAndDeleteMessage,
            hasError,
          }}
          iconType={isPlaying ? PlayIconType.Pause : PlayIconType.Play}
          onClick={onTogglePlay}
        />
      ) : (
        <AudioState
          className={styles.icon}
          groupId={groupId}
          messageId={messageId}
          payloadId={payloadId}
          iconType={isPlaying ? PlayIconType.Pause : PlayIconType.Play}
          onClick={onTogglePlay}
        />
      )}
      <div className={styles.info}>
        <FileName
          className={styles.title}
          name={fileName}
        />
        <AudioPlayerTrackCaption
          isPlaying={isActiveTrack}
          duration={duration}
          progress={progress}
          size={size}
        />
      </div>
    </div>
  );
};
