import classNames from 'classnames';
import React, { FC } from 'react';

import CloseIcon from 'ICONS/ic-close.svg';
import PlayListIcon from 'ICONS/ic-play-list.svg';
import noop from 'UTILS/noop';

import { ClickOutside } from '../../ClickOutside/ClickOutside';
import { FAB } from '../../FAB/FAB';
import { MediaPlayerProgress } from '../components/MediaPlayerProgress/MediaPlayerProgress';
import { useMediaPlayerProgress } from '../hooks/useMediaPlayerProgress';
import { useMediaPlayerSpeed } from '../hooks/useMediaPlayerSpeed';
import { defaultAudioPlayerConfig } from './AudioPlayer.constants';
import { useAudioPlayerPresenter } from './AudioPlayer.presenter';
import styles from './AudioPlayer.styles.m.css';
import { IAudioPlayerConfig, IAudioPlayerTrack } from './AudioPlayer.types';
import { AudioPlayerControls } from './AudioPlayerControls/AudioPlayerControls';
import { AudioPlayerPlayList } from './AudioPlayerPlayList/AudioPlayerPlayList';
import { AudioPlayerTitle } from './AudioPlayerTitle/AudioPlayerTitle';
import { useAudioPlayerTrackManager } from './AudioPlayerTrackManager.presenter';

export * from './AudioPlayer.types';

export interface IAudioPlayerProps {
  className?: string;
  track?: IAudioPlayerTrack;
  autoPlay?: boolean;
  isPlaying?: boolean;
  config?: Partial<IAudioPlayerConfig>;
  isShowPlayList?: boolean;
  playList?: IAudioPlayerTrack[];
  isShowOrder?: boolean;
  isShowRepeat?: boolean;
  hasPrevious?: boolean;
  hasNext?: boolean;
  progress?: number | null;
  progressPercentage?: number | null;
  isPlayListOpened?: boolean;
  onTogglePlay?(isPlaying: boolean): void;
  onPrevious?(): void;
  onNext?(): void;
  onConfigUpdate?(config: Partial<IAudioPlayerConfig>): void;
  onClose?(): void;
  onTitleClick?(): void;
  onProgress?(progress: number, progressPercentage: number): void;
  onChangeTrack?(track: IAudioPlayerTrack): void;
  onTogglePlayList?(isOpened: boolean): void;
}

export const AudioPlayer: FC<IAudioPlayerProps> = ({
  className,
  track = null,
  autoPlay = false,
  isPlaying: newIsPlayingState = false,
  config: playerConfig,
  isShowPlayList = false,
  playList = [],
  isShowOrder = false,
  isShowRepeat = false,
  progress: newProgress = null,
  progressPercentage: newProgressPercentage = null,
  isPlayListOpened: newIsPlayListOpenedState = false,
  onTogglePlay = noop,
  onConfigUpdate = noop,
  onTitleClick = noop,
  onClose,
  onProgress = noop,
  onChangeTrack = noop,
  onTogglePlayList = noop,
}) => {
  const config = {
    ...defaultAudioPlayerConfig,
    ...playerConfig,
  };

  const {
    containerRef,
    audioRef,
    isPlaying,
    isPlayListOpened,
    togglePlay,
    togglePlayList,
    closePlayList,
    play,
    pause,
  } = useAudioPlayerPresenter({
    track,
    autoPlay,
    newIsPlayingState,
    newIsPlayListOpenedState,
    onTogglePlay,
    onTogglePlayList,
  });
  const { progress, progressMax, setProgress, updateProgressMax, updateProgress } =
    useMediaPlayerProgress({
      url: track?.url || '',
      mediaRef: audioRef,
      newProgress,
      newProgressPercentage,
      onProgress,
    });
  const { setSpeed } = useMediaPlayerSpeed({
    url: track?.url || '',
    mediaRef: audioRef,
    config,
    onConfigUpdate,
  });
  const { hasNext, hasPrevious, onNext, onPrevious } = useAudioPlayerTrackManager({
    activeTrack: track,
    playList,
    onChangeTrack,
  });

  return (
    <ClickOutside
      reference={containerRef.current}
      onClickOutside={isShowPlayList ? closePlayList : undefined}
    >
      <div
        className={classNames(styles.container, className)}
        ref={containerRef}
      >
        <audio
          src={track?.url}
          autoPlay={autoPlay}
          ref={audioRef}
          onLoadedMetadata={updateProgressMax}
          onTimeUpdate={updateProgress}
        />

        {isShowPlayList && (
          <AudioPlayerPlayList
            isPlaying={isPlaying}
            activeTrack={track}
            items={playList}
            progress={progress}
            isOpened={isPlayListOpened}
            onTogglePlay={togglePlay}
            onChangeTrack={onChangeTrack}
          />
        )}

        <div className={classNames(styles.content, { [styles.listOpened]: isPlayListOpened })}>
          <div className={styles.panel}>
            {isShowPlayList && (
              <FAB
                Icon={PlayListIcon}
                size="10"
                iconSize="10"
                theme="15"
                onClick={togglePlayList}
              />
            )}

            <AudioPlayerTitle
              className={styles.title}
              title={track?.title || ''}
              description={track?.description || ''}
              onClick={onTitleClick}
            />

            {onClose && (
              <FAB
                Icon={CloseIcon}
                size="10"
                iconSize="10"
                theme="15"
                onClick={onClose}
              />
            )}
          </div>

          <div className={styles.controls}>
            <MediaPlayerProgress
              className={styles.progress}
              isPlaying={isPlaying}
              progress={progress}
              progressMax={progressMax}
              isShowBuffer={false}
              isShowTooltip={true}
              onPlay={play}
              onPause={pause}
              onProgress={setProgress}
            />

            <AudioPlayerControls
              isPlaying={isPlaying}
              config={config}
              isShowOrder={isShowOrder}
              isShowRepeat={isShowRepeat}
              hasPrevious={hasPrevious}
              hasNext={hasNext}
              onTogglePlay={togglePlay}
              onPrevious={onPrevious}
              onNext={onNext}
              onConfigUpdate={onConfigUpdate}
              onChangeSpeed={setSpeed}
            />
          </div>
        </div>
      </div>
    </ClickOutside>
  );
};
