import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';
import Translate from 'MAIN/ChatView/Messages/Message/bubbles/components/Translate';

import { MessageLayout } from '../../MessageLayout/MessageLayout';
import { ForwardMessage } from '../components/ForwardMessage/ForwardMessage';
import Quote from '../components/Quote';
import { SenderName } from '../components/SenderName/SenderName';
import StatusBar from '../components/StatusBar';
import { ThreadButton } from '../components/ThreadButton/ThreadButton';
import styles from './FileBubble.styles.m.scss';

export const FileBubble = observer((props) => {
  const { message, children, messageViewPageType, isView } = props;
  const { comment } = message.renderPayload;

  return (
    <MessageLayout
      message={message}
      className={styles.bubble}
      messageViewPageType={messageViewPageType}
      isView={isView}
    >
      <div className={styles.container}>
        <SenderName
          message={message}
          className={styles.senderName}
        />
        {message.quotedMessage && (
          <Quote
            message={message.quotedMessage}
            className={styles.quote}
          />
        )}
        {message.forward && !message.isSavedMessagesLeft && (
          <ForwardMessage
            message={message}
            className={styles.forward}
          />
        )}
        {children}
        {!comment && (
          <StatusBar
            className={styles.statusBar}
            message={message}
          />
        )}
        {comment && comment.length && (
          <div className={styles.comment}>
            <TextRenderer
              userMentions={message.payload.userMentions}
              keyWords={message.payload.keyWords}
            >
              {comment}
            </TextRenderer>
            <StatusBar
              message={message}
              className={styles.commentedStatusBar}
            />
          </div>
        )}
      </div>
      <ThreadButton
        message={message}
        className={styles.threadButton}
      />
      <Translate message={message} />
    </MessageLayout>
  );
});
