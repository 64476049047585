import { observer } from 'mobx-react';
import React from 'react';

import { TextRenderer } from 'APP/components/TextRenderer/TextRenderer';

import { Layout } from '../components/Layout';
import styles from './ButtonsPayload.styles.m.scss';

export const ButtonsPayload = observer(({ message, title, onClick }) => {
  const { text, typeDescription } = message.payload;

  if (!message) {
    return null;
  }

  return (
    <Layout
      title={title}
      messageId={message.id}
      onClick={onClick}
    >
      <TextRenderer
        isPlainText
        className={styles.text}
      >
        {text || typeDescription}
      </TextRenderer>
    </Layout>
  );
});
