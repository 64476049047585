import classNames from 'classnames';
import React, { FC } from 'react';

import { Tooltip } from 'UIKIT/Tooltip/Tooltip';
import noop from 'UTILS/noop';

import { IMediaPlayerProgressBuffer } from '../../hooks/useMediaPlayerProgress';
import { useMediaPlayerProgressPresenter } from './MediaPlayerProgress.presenter';
import styles from './MediaPlayerProgress.styles.m.css';

interface IMediaPlayerProgressProps {
  className?: string;
  isPlaying: boolean;
  progress: number;
  progressMax: number;
  isShowBuffer?: boolean;
  progressBuffer?: IMediaPlayerProgressBuffer[];
  isShowTooltip?: boolean;
  onPlay(): void;
  onPause(): void;
  onProgress(progress: number): void;
  onProgressSelection?(isSelected: boolean): void;
}

export const MediaPlayerProgress: FC<IMediaPlayerProgressProps> = ({
  className,
  isPlaying,
  progress,
  progressMax,
  isShowBuffer = true,
  progressBuffer = [],
  isShowTooltip = false,
  onPlay,
  onPause,
  onProgress,
  onProgressSelection = noop,
}) => {
  const {
    progressRef,
    onMouseDown,
    progressPercentage,
    bufferPercentage,
    isChangingPosition,
    tooltipTime,
  } = useMediaPlayerProgressPresenter({
    isPlaying,
    progress,
    progressMax,
    isShowBuffer,
    progressBuffer,
    isShowTooltip,
    onPlay,
    onPause,
    onProgress,
    onProgressSelection,
  });

  const pinClass = classNames(styles.progressPin, {
    [styles.progressPinActive]: isChangingPosition,
  });

  return (
    <Tooltip
      text={tooltipTime}
      followCursor="horizontal"
      disabled={!isShowTooltip}
    >
      <div
        className={classNames(styles.progress, className)}
        ref={progressRef}
        role="progressbar"
        tabIndex={0}
        onMouseDown={onMouseDown}
      >
        <div className={styles.progressBarWrapper}>
          <div className={styles.progressBackground}></div>
          {isShowBuffer &&
            bufferPercentage.map((item) => (
              <div
                className={styles.progressBuffer}
                key={`${item.start}_${item.width}`}
                style={{ left: `${item.start}%`, width: `${item.width}%` }}
              ></div>
            ))}
          <div
            className={styles.progressBar}
            style={{ width: `${progressPercentage}%` }}
          ></div>
        </div>
        <div
          className={pinClass}
          style={{
            left: `${progressPercentage}%`,
          }}
        ></div>
      </div>
    </Tooltip>
  );
};
