import { observer } from 'mobx-react';
import React from 'react';

import { PayloadType } from 'APP/model/message/messageModel.types';
import AdIcon from 'ICONS/messages/ic-ad-16.svg';
import AlbumIcon from 'ICONS/messages/ic-album-16.svg';
import AudioIcon from 'ICONS/messages/ic-audio-16.svg';
import ContactIcon from 'ICONS/messages/ic-contact-16.svg';
import FileIcon from 'ICONS/messages/ic-file-16.svg';
import ImageIcon from 'ICONS/messages/ic-image-16.svg';
import LocationIcon from 'ICONS/messages/ic-location-16.svg';
import StickerIcon from 'ICONS/messages/ic-sticker-16.svg';
import VideoIcon from 'ICONS/messages/ic-video-16.svg';
import VoiceIcon from 'ICONS/messages/ic-voice-16.svg';

import { CommonPayload } from '../CommonPayload/CommonPayload';

const iconComponents = {
  [PayloadType.Image]: ImageIcon,
  [PayloadType.Video]: VideoIcon,
  [PayloadType.Album]: AlbumIcon,
  [PayloadType.File]: FileIcon,
  [PayloadType.AudioMessage]: AudioIcon,
  [PayloadType.VoiceMessage]: VoiceIcon,
  [PayloadType.Sticker]: StickerIcon,
  [PayloadType.Location]: LocationIcon,
  [PayloadType.Contact]: ContactIcon,
  [PayloadType.Advertisement]: AdIcon,
};

export const DefaultPayload = observer(({ name, payload }) => {
  const comment = String(payload?.comment || '').trim();

  return (
    <CommonPayload
      Icon={iconComponents[payload?.payloadType] || null}
      name={name}
      description={comment || payload?.text || payload?.typeDescription || ''}
    />
  );
});
