import React, { memo } from 'react';

import { TPlayIconTheme } from 'UIKIT/PlayIcon/PlayIcon.types';

import styles from '../PlayIcon.styles.m.css';

interface IPlayIconErrorProps {
  theme: TPlayIconTheme;
}

export const PlayIconError = memo<IPlayIconErrorProps>((props) => {
  const { theme } = props;

  return (
    <>
      <circle
        cx="39"
        cy="39"
        r="9"
        className={styles[`play-icon-error-${theme}`]}
      />
      <path
        d="M40 42C40 42.5523 39.5523 43 39 43C38.4477 43 38 42.5523 38 42C38 41.4477 38.4477 41 39 41C39.5523 41 40 41.4477 40 42ZM40 39C40 39.5523 39.5523 40 39 40C38.4477 40 38 39.5523 38 39V36C38 35.4477 38.4477 35 39 35C39.5523 35 40 35.4477 40 36V39Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.12 29.8855C43.6921 28.0247 44 26.0483 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44C26.0483 44 28.0247 43.6921 29.8855 43.12C29.3167 41.8637 29 40.4688 29 39C29 33.4772 33.4772 29 39 29C40.4688 29 41.8637 29.3167 43.12 29.8855Z"
        className={styles[`play-icon-${theme}`]}
      />
    </>
  );
});
