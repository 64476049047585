import classNames from 'classnames';
import React, { memo, MouseEventHandler } from 'react';

import { TPlayIconTheme } from 'UIKIT/PlayIcon/PlayIcon.types';

import styles from '../PlayIcon.styles.m.css';

interface IPlayIconLoadingProps {
  onCancel?: MouseEventHandler<SVGGElement>;
  theme: TPlayIconTheme;
}

export const PlayIconLoading = memo<IPlayIconLoadingProps>((props) => {
  const { theme, onCancel } = props;

  return (
    <>
      <circle
        cx="39"
        cy="39"
        r="9"
        className={styles[`play-icon-${theme}`]}
      />
      <g
        className={styles.pointer}
        onClick={(event) => {
          event.stopPropagation();
          onCancel?.(event);
        }}
      >
        <circle
          cx="39"
          cy="39"
          r="9"
          className={styles[`play-icon-${theme}`]}
        />
        <path
          d="M41.7174 36.228C41.4142 35.924 40.9226 35.924 40.6194 36.228L38.9805 37.8714L37.3806 36.2671C37.0774 35.9631 36.5858 35.9631 36.2826 36.2671C35.9794 36.5712 35.9794 37.0641 36.2826 37.3681L37.8825 38.9723L36.2274 40.6319C35.9242 40.9359 35.9242 41.4288 36.2274 41.7329C36.5306 42.0369 37.0222 42.0369 37.3254 41.7329L38.9805 40.0733L40.6746 41.772C40.9778 42.076 41.4694 42.076 41.7726 41.772C42.0758 41.468 42.0758 40.975 41.7726 40.671L40.0785 38.9723L41.7174 37.329C42.0206 37.025 42.0206 36.532 41.7174 36.228Z"
          fill="white"
        />
        <circle
          className={classNames(styles.progress)}
          cx="39"
          cy="39"
          r="7"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.12 29.8855C43.6921 28.0247 44 26.0483 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44C26.0483 44 28.0247 43.6921 29.8855 43.12C29.3167 41.8637 29 40.4688 29 39C29 33.4772 33.4772 29 39 29C40.4688 29 41.8637 29.3167 43.12 29.8855Z"
        className={styles[`play-icon-${theme}`]}
      />
    </>
  );
});
