import Tasks from 'APP/Tasks';
import { StreamType } from 'APP/constants/calls';
import { callModel } from 'APP/model/model';
import api from 'APP/packages/api';
import logger from 'APP/packages/logger';
import Entities from 'APP/store';
import { t } from 'MODULE/i18n/service';

export const stopSharingScreen = async (): Promise<void> => {
  const call = Entities.Calls.ongoingCall;
  if (!call?.me) {
    return;
  }
  if (call.me.isSharedScreen) {
    call.opponents.deleteOpponent(call.me.shareScreenUid);
    call.me.setSharedScreen(false);
    await call.me.audioVideoStreamer.stopScreenShare();

    if (call.isRecording) {
      await api.calls.stopScreenSharing({
        channelId: call.channelId,
        recordingId: call.recordingId,
      });
    }
  }
};

let isStartScreenSharing = false;

export const shareScreen = async (electronSourceId?: string): Promise<void> => {
  if (!isStartScreenSharing) {
    const call = Entities.Calls.ongoingCall;

    const isScreenSharing =
      call?.isScreenSharing && call?.opponents.screenSharingOpponent?.hasVideo;

    if (isScreenSharing) {
      Tasks.app.showAlert(t('calls_sharing_alert'));

      return;
    }

    if (!call?.me) {
      return;
    }

    const canUnmute = await call?.me.canUnmute(StreamType.ScreenSharing);

    if (!canUnmute || !call.me.channelId) {
      return;
    }

    isStartScreenSharing = true;
    try {
      const isNeedToGetToken = call.me.audioVideoStreamer.needToGetScreenSharingToken;

      if (isNeedToGetToken && call && call.me.channelId) {
        const info = await callModel.getCallInfo(
          { channelId: call.me.channelId, providerType: call.providerType },
          call.callType
        );
        call.me.setShareScreenToken(info.shareScreenToken);
      }

      const result = await call.me.audioVideoStreamer.startScreenShare({
        channelId: call.me.channelId,
        token: call.me.shareScreenToken,
        uid: call.me.shareScreenUid,
        needToPublish: true,
        electronSourceId,
        onStopSharingScreen: stopSharingScreen,
      });

      if (result) {
        call.me.setSharedScreen(true);

        if (call.isRecording && call.channelId && call.recordingId) {
          await Tasks.calls.sendStartScreenSharingEvent({
            channelId: call.channelId,
            recordingId: call.recordingId,
          });
        }
      }
    } catch (e) {
      logger.get('Tasks').error('calls.screenSharing', e);
    } finally {
      isStartScreenSharing = false;
    }
  }
};
