import React, { memo } from 'react';

import { TPlayIconTheme } from 'UIKIT/PlayIcon/PlayIcon.types';

import styles from '../PlayIcon.styles.m.css';

interface IPlayIconCompleteProps {
  theme: TPlayIconTheme;
}

export const PlayIconComplete = memo<IPlayIconCompleteProps>((props) => {
  const { theme } = props;

  return (
    <>
      <circle
        cx="39"
        cy="39"
        r="9"
        className={styles[`play-icon-${theme}`]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.177 36.2831C41.6063 35.8941 42.2848 35.9074 42.6969 36.314C43.1137 36.7252 43.0986 37.3811 42.6643 37.7746L39.1477 41.7146C38.7278 42.0951 38.0686 42.0951 37.6487 41.7146L35.3357 39.6189C34.9014 39.2254 34.8863 38.5695 35.3031 38.1582C35.7152 37.7517 36.3937 37.7384 36.823 38.1274L38.3982 39.5547L41.177 36.2831Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.12 29.8855C43.6921 28.0247 44 26.0483 44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44C26.0483 44 28.0247 43.6921 29.8855 43.12C29.3167 41.8637 29 40.4688 29 39C29 33.4772 33.4772 29 39 29C40.4688 29 41.8637 29.3167 43.12 29.8855Z"
        className={styles[`play-icon-${theme}`]}
      />
    </>
  );
});
