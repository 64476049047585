import classNames from 'classnames';
import { observer } from 'mobx-react';
import React from 'react';

import { MiniatureMessage } from 'APP/main/ChatView/components/MiniatureMessage/MiniatureMessage';

import styles from './styles.m.css';

const QuotedMessage = ({ message, className }) => {
  if (!message) {
    return null;
  }

  return (
    <div
      className={classNames(styles.container, className)}
      data-test="reply-message"
    >
      <MiniatureMessage
        message={message}
        title={<p className={styles.title}>{message.avatarTitle}</p>}
      />
    </div>
  );
};

export default observer(QuotedMessage);
